<template>
  <v-container>
    <v-row>
      <v-col>
        <v-img
          class="white--text align-end"
          :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
          src="@/assets/banner.png"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-calendar-range</v-icon>
          <p class="text-body-1">
            Wir waren
            <b>113</b>
            Tage unterwegs
          </p>
        </v-layout>
      </v-col>
      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-map</v-icon>
          <p class="text-body-1">
            <b>{{ this.statistics.km_gesamt }} km</b>
            zurückgelegt
          </p>
        </v-layout>
      </v-col>

      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-map-marker-distance</v-icon>
          <p class="text-body-1">
            <b>{{ this.statistics.km_durchschnitt }} km</b>
            Durchschnittliche Strecke pro Tag
          </p>
        </v-layout>
      </v-col>
      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-elevation-rise</v-icon>
          <p class="text-body-1">
            <b>{{ this.statistics.hoehenmeter_gesamt }}</b>
            gefahrene Höhenmeter
          </p>
        </v-layout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout justify-center>
          <v-btn to="statistiken" depressed color="primary">Hier findest du mehr Statistiken</v-btn>
        </v-layout>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="6">
        <v-card to="karte" class="mx-auto" :height="$vuetify.breakpoint.xs ? '250px' : '410px'">
          <v-img
            class="white--text align-end"
            :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
            src="@/assets/img/home/openstreetmap.png"
          ></v-img>
          <v-card-title style="word-break: break-word">Du möchtest wissen wo wir gerade sind?</v-card-title>
          <v-card-text class="text--primary py-0">
            <div>Hier findest du eine interaktive Karte mit unserer gesamten Reiseroute</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card href="https://www.youtube.com/watch?v=qu_MBKwAse0">
          <div><youtube fit-parent video-id="qu_MBKwAse0" ref="youtube"></youtube></div>
          <v-card-title class="py-0" style="word-break: break-word">Lust auf mehr?</v-card-title>
          <v-card-text class="text--primary my-2">
            <div>Hier findest du unser neustes YouTube Video</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-12">
      <v-col lg="6">
        <v-card to="fahrrad" class="mx-auto" :height="$vuetify.breakpoint.xs ? '250px' : '410px'">
          <v-img
            class="white--text align-end"
            :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
            src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrraeder_Alex.jpeg?alt=media&token=a471a055-63c2-4f22-8892-4940d2e0c0ef"
          ></v-img>
          <v-card-title style="word-break: break-word">Interessiert dich unser Fahrrad?</v-card-title>
          <v-card-text class="text--primary py-0" style="height: 44px">
            <div>Unsere Koga Worldtraveller im Detail</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card to="ausruestung" class="mx-auto" :height="$vuetify.breakpoint.xs ? '250px' : '410px'">
          <v-img
            class="white--text align-end"
            :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
            src="@/assets/img/home/ausruestung_card.jpg"
          ></v-img>
          <v-card-title style="word-break: break-word">Hier geht es zu unserer Ausrüstung</v-card-title>
          <v-card-text class="text--primary py-0" style="height: 44px">
            <div>Eine Auflistung unserer Ausrüstungsgegenstände</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-2 mt-12">
      <div class="text-h4 text-center">Über uns</div>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="justify-center d-flex">
        <v-img contain max-height="400" src="@/assets/img/home/Bild_home.jpeg"></v-img>
      </v-col>
      <v-col cols="12" lg="6" class="justify-center d-flex">
        <p>
          Wir sind Vanessa und Alex, zwei Geoinformatiker aus Süddeutschland, die ihren Job an den Nagel gehangen haben
          um der Abenteuerlust nachzugehen. Lange haben wir überlegt, wie wir unsere Reise bestreiten wollen und haben
          uns aus Kosten- aber vor allem aus ökologischen Gründen für das Fahrrad als Fortbewegungsmittel entschieden.
          Und das, obwohl wir beide (noch 😉) nicht viel von Fahrrädern verstehen! Aber was nicht ist wird sicher noch
          werden. Wir würden uns freuen, wenn ihr uns auf unserer Reise begleitet, auf dieser Website oder auf YouTube
          und Instagram.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statistics_json from "../../json/statistics.json";
export default {
  data() {
    return { days_til_start_date: null, days_since_start_date: null, statistics: statistics_json };
  },
  mounted() {
    //Caclulate start date
    let today = new Date();
    let start_date = new Date("06/06/2022");
    var one_day = 1000 * 60 * 60 * 24;
    this.days_til_start_date = Math.ceil((start_date.getTime() - today.getTime()) / one_day);
    this.days_since_start_date = Math.abs(this.days_til_start_date) + 1;
  },
};
</script>
