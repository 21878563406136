<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="text-h4 text-center py-6">
          General Information regarding Planning
        </div></v-col
      >
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"
            ><img src="@/assets/Avatar_Vanessa.jpg"
          /></v-avatar>
          <p class="text--disabled text-overline text-left">29. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Overview</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title
              >General Information regarding Planning</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="9"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
      >
        <v-row no-gutters>
          <div
            id="1"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            General Information regarding Planning
          </div>
          <p>
            We have compiled some information on travel planning and tried to
            prepare the information in the best possible way for you. To make
            the planning process easier for you, we have created some checklists
            and templates for free download.
          </p>
          <p>
            For more details on the individual categories, please have a look
            here:
          </p>
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="0" md="0" lg="1" xl="2"></v-col>
              <v-col
                cols="12"
                md="12"
                lg="10"
                xl="8"
                class="main-content rounded-lg pa-2 pa-md-4"
              >
                <v-row no-gutters>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/verkehrsregeln" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title
                          >Traffic rules for cyclists in Europe</v-card-title
                        >
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col sm="6">
                    <v-card to="/reiseplanung/reisemedizin" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Travel Medication</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/versicherungen" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Insurances</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/finanzierung" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Cost calculation</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="0" md="0" lg="1" xl="2"></v-col>
            </v-row>
          </v-container>
          <v-alert outlined type="warning" elevation="1">
            We have compiled this information to the best of our knowledge and
            belief. However, please also inform yourself. In case you discover
            any mistakes, please feel free to inform us by e-mail to
            <a href="mailto:mail@bikingbeyond.de">mail@bikingbeyond.de</a>
            with proof or a reference.
          </v-alert>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="0"
        md="3"
        class="d-flex flex-column align-end justify-start"
      >
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Overview</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title
                >General Information regarding Planning</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
