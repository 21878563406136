<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FVanessa_Haengematte.jpeg?alt=media&token=63de84ae-6e04-47c3-8f99-2ed205057473"
          max-height="400"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Camping Gear</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">15. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Overview</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>1. Tent</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Sleeping system</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Kitchen Setup</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(4)">
            <v-list-item-title>4. Luxury Gear</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''">
        <v-row no-gutters>
          <v-col>
            <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
              1. Tent
            </div>
            <p>
              From past camping trips, hiking vacations, and a Camino Portugues, we've learned: a tent of 1.20 meters
              width is too small for 2 people. In fact, we need space: when it rains and you want to store all your
              equipment, to sleep in peace or even to lounge in the tent for a few hours in the evening fleeing from
              mosquitoes. Space is worth its weight in gold. So we were looking for a lightweight 3 person dome tent for
              the two of us. We knew Big Agnes from a friend, as a Big Agnes was her faithful companion on the Pacific
              Crest Trail. We therefore decided on the Blacktail 3 in green. With just 2.58 kg but interior dimensions
              of 2.25 x 1.75 x 1.10 and 2 entrances it is exactly what we were looking for. To protect it a bit better
              we use an additional tent pad to go underneath.
            </p>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Ftent.jpeg?alt=media&token=da67c05b-b076-4154-a8bc-e9364b32be6c"
                  max-height="500"
                ></v-img>
              </v-col>
            </v-row>
            <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              2. Sleeping system
            </div>
            <p>
              Our self-inflating Kalla 5.0 mats from Frilufts insulate well, do not crackle, are not slippery and are
              still 5 cm thick. When we were hiking, we used the Therm-A-Rest NeoXAir Lite, which is really a great
              companion when it comes to every gram and a small pack size. At that time, however, we noticed that you
              quickly slide down from too thick, slippery and small mats. Therefore, the almost rectangular shape of the
              Kalla 5.0 in addition to the soft surface is exactly what we were looking for and a real tip. However, it
              weighs 910 grams and has a significantly larger pack size than the NeoAirXLiite. Vanessa freezes quickly
              and uses the relatively warm 3-season synthetic fiber sleeping bag Lamina from Mountain Hardware with a
              comfort temperature of -1 ° C and has already been very satisfied with the sleeping bag on several trips
              even in summer. You can always uncover yourself if it gets to hot ;) Alex is not so squeamish and has a
              sleeping bag from Frijlufts with 5 ° C comfort temperature. To protect the sleeping bags and for
              particularly cold nights, we also have an inlet from Cocon with us. In warm nights you can also use only
              the Inlet as a blanket. Our pillow is the Sea to Summit Foamcore Pillow, which is filled with small foam
              pieces and can be rolled up to 20% of its size. The pillow is really a perfect find and worth every ounce!
            </p>
            <div id="3" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              3. Kitchen Setup
            </div>
            <p>
              We did the math: if you cook a nice meal and tea every night, and then don't want to miss your coffee in
              the morning and warm porride from time to time, you need quite a few gas cartridges. At most every 2 weeks
              you need a refill. But not everywhere in the world they are as easy to get as they are in central europe.
              That's why we bought a stove that can handle just about anything: the Primus OmniFuel 2. We run it mainly
              on gas station fuel. In the long run, that's a lot cheaper than running on gas with camping cartridges. A
              disadvantage of the operation with gasoline is, however, that you must first learn to use the stove
              properly and that the burner is quite loud.
            </p>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Fstove.jpeg?alt=media&token=065a4a0b-0ba5-4a5e-9e05-ec528090f580"
                  max-height="400"
                ></v-img>
              </v-col>
            </v-row>
            <p>
              two large pots was important to us. In the long run, I can't cook well for two hungry cyclists with a 1
              liter pot, but finding larger pots with reasonable weight is not so easy. We are very happy about our
              Optimus cooking set. It consists of 2 pots, a pan, lid and an insulating protector.
              https://www.outdoortrends.de/optimus-terra-camp-4-kochtopf-set.html?gclid=EAIaIQobChMI1PTC8IC39QIV049oCR0DIAuLEAQYAyABEgJVivD_BwE
              Of course we also brought dishes, a folding kitchen knife from Victorinox, a board and a thermo mug each
              for coffee in the morning. To brew it we chose the Java Drip coffee filter from GSI, which weighs only 20
              grams. https://www.globetrotter.de/gsi-kaffeefilter-ultralight-java-drip-1008948/?sku=5637280248 To do the
              dishes we used a foldable tub from Ortlieb.
            </p>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FAusruestung_Cooking_Overwiew.jpeg?alt=media&token=6e19e04f-ce8c-4db5-8882-6945be897f84"
                  max-height="500"
                ></v-img>
              </v-col>
            </v-row>
            <div id="4" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              4. Luxury Gear
            </div>
            <p>
              We picked up a few luxury items, including an ultra light camping chair and a hammock to make the time we
              don't spend in the saddle comfortable. The Helinox Chair Zero is well-known among outdoorsmen. Retailing
              for 120 € it does not come in cheap, but enormously light: it weights just 490 grams. The ultra-light
              hammock from Exped weighs less than 400 grams with a suspension system and can also be used as a sunshade
              if installed between trees correctly.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Overview</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>1. Tent</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Sleeping system</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Kitchen Setup</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(4)">
              <v-list-item-title>4. Luxury Gear</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
