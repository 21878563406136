<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrraeder_Alex.jpeg?alt=media&token=a471a055-63c2-4f22-8892-4940d2e0c0ef"
          max-height="600"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Our bikes</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">15. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Overview</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>1. How we got our bikes</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. The Koga World Traveller</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. The alternatives</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''">
        <v-row no-gutters>
          <v-col>
            <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
              1. How we got our bikes
            </div>
            <p>
              Yeah...where to start. Buying a bike during the pandemic is hard. Buying a dedicated travel bike is
              harder. Buying TWO bikes : almost impossible. At least that's what almost every bike shop in southern
              Germany told us. So we rattled off various stores in half of Germany over 3 months in late summer 2021.
              Dealer after dealer confirmed us thereby to put us on the waiting list, but made it clear to us that we
              should better not expect a bike in the next 12 - 20 months. What were we thinking trying to buy a bike
              only one year before the beginning of the journey! Our goals - Utopian! Finally we found an inseration of
              two bikes on eBay Kleinanzeigen - a way, which would not have occurred to me at first. We drove 2 times
              about 600 kilometers to MS Zweirad in Heinsberg and finally found what we were looking for.
            </p>
            <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              2. The Koga World Traveller
            </div>

            <p>
              We both decided on the
              <b>Koga World Traveller model of the year 2021</b>
              . Alex rides the bike in 54 cm frame size, Vanessa in 47 cm. With a permissible total weight of 150
              kilograms, a stable aluminum frame and pre-mounted lowriders, the World Traveller from Koga is one of the
              classics on the touring bike market. Important for us were the hydraulic disc brakes, with which the 2021
              model was equipped for the first time in the Word Traveller series. The bike has 30 gears through the
              installed Shimano Deore XT T8000 3x10 shifting group. The wide, pre-mounted Schwalbe Marathon tires have
              extra puncture protection. For more specifications, feel free to check out the latest models on the
              manufacturer Koga's website.
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-img
              contain
              src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrrad.jpeg?alt=media&token=08f963fc-bbc2-44ab-a612-82ad5e72097b"
              max-height="500"
            ></v-img>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <p>
              What we particularly appreciated when test riding our Koga is the feeling of great stability with which
              the bike rides. The riding sensation is as safe as if you were riding on rails...or a small tank ;)
            </p>
            <p>
              Unfortunately, Koga follows gender roles from the last century when choosing the frame shape and makes
              frames under 50 cm only in trapezoid shape. A trapezoid frame not only has the "granny flair" that many
              sporty young women are reluctant to choose, but also offer significantly fewer options for mounting bottle
              cages and such in the frame.
            </p>
            <p>
              With a retailing price of 2199 €, the World Traveller was just within our budget, as many touring bikes
              are in a price range starting at 3500 €. Since our purchase, however, the new price has since been raised
              to 2299 €. This price increase also affects other bikes that we had favored. Check out our YouTube Video
              if you are interested in how we pimped our bikes!
            </p>
            <div id="3" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              3. The alternatives
            </div>
            <p>
              To be honest, the World Traveller was not the bike we had in mind from the beginning. First, we looked at
              the
              <b>TX400 and TX800 from Fahrradmanufaktur</b>
              and were also quickly aware of the
              <b>Cube Kathmandu SL</b>
              , all in a price range of 1900 to 2100 €. Since the models were unfortunately not available, we also
              tested bikes of the brand
              <b>Tout Terrain</b>
              , which, however, did not appeal to us personally. Other bike travelers introduced us to the company
              <b>Böttcher</b>
              with the
              <b>Böttcher Evolution</b>
              . Since the company is based in northern Germany, there were no dealers in our region ( which we
              admittedly extended to 4 states), which carry these whatsoever.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Overview</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>1. How we got our bikes</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. The Koga World Traveller</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. The alternatives</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
