<template>
  <v-footer v-if="!hideFooter" color="primary lighten-1" padless>
    <cookie-law
      :buttonText="$t('footer.cookies.buttonAccept')"
      :buttonDecline="true"
      :buttonDeclineText="$t('footer.cookies.buttonDecline')"
      v-on:accept="enableTracking()"
    >
      <div slot="message">
        {{ $t("footer.cookies.message") }}
        <router-link to="impressum">{{ $t("footer.cookies.impressum") }}</router-link>
      </div></cookie-law
    >
    <v-row justify="center" no-gutters>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn small color="white" text rounded class="my-2" href="mailto:mail@bikingbeyond.de">
          {{ $t("footer.kontakt") }}
          <v-icon small class="mx-1"> mdi-email </v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn
          href="https://www.youtube.com/channel/UCJ9mY8_S815wB6LUsa81mHQ"
          target="_blank"
          small
          color="white"
          text
          rounded
          class="my-2"
        >
          Youtube
          <v-icon small class="mx-1"> mdi-youtube </v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn
          href="https://www.instagram.com/biking_beyond/"
          target="_blank"
          small
          color="white"
          text
          rounded
          class="my-2"
        >
          Instagram
          <v-icon small class="mx-1"> mdi-instagram </v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" sm="2" lg="1">
        <v-btn to="/impressum" small color="white" text rounded class="my-2" active-class="no-active">
          {{ $t("footer.impressum") }}
          <v-icon small class="mx-1"> mdi-gavel </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw },
  data() {
    return { hideFooter: false };
  },
  methods: {
    disableTracking() {
      this.$gtag.optOut();
    },
    enableTracking() {
      this.$gtag.optIn();
    },
    showFooter() {
      if (this.$vuetify.breakpoint.xsOnly && this.$route.path == "/karte") {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
    },
  },
  created() {
    this.showFooter();
  },
  watch: {
    $route() {
      this.showFooter();
    },
  },
};
</script>

<style></style>
