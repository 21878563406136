<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrraeder_Alex.jpeg?alt=media&token=a471a055-63c2-4f22-8892-4940d2e0c0ef"
          max-height="600"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Unsere Fahrräder</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">15. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>1. Wie wir zu unseren Rädern kamen</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Der Koga World Traveller</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Die Alternativen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''">
        <v-row no-gutters>
          <v-col>
            <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
              1. Wie wir zu unseren Rädern kamen
            </div>
            <p>
              Ja…wo soll ich da anfangen. Ein Fahrrad während der Pandemie zu kaufen ist schwer. Ein spezielles
              Reisefahrrad zu kaufen schwerer. ZWEI Räder zu kaufen : fast unmöglich. Das sagte uns jedenfalls fast
              jeder Radhändler in Süddeutschland. Wir klapperten also über 3 Monate im Spätsommer 2021 diverse Läden in
              halb Deutschland ab. Händler für Händler bestätigte uns dabei, uns auf die Warteliste zu setzten, machte
              uns aber klar, dass wir besser nicht mehr mit einem Rad in den nächsten 12 - 20 Monaten rechnen sollten.
              Was uns einfalle erst ein Jahr vor Beginn der Reise ein Rad kaufen zu wollen! Unsere Ziele - Utopisch!
              Schließlich fanden wir über eBay Kleinanzeigen unsere Räder - ein Weg, der mir zunächst nicht eingefallen
              wäre. Wir fuhren 2 mal etwa 600 Kilometer zu MS Zweirad nach Heinsberg und wurden dort endlich fündig.
            </p>
            <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              2. Der Koga World Traveller
            </div>

            <p>
              Wir haben uns beide für das
              <b>Koga World Traveller Modell des Jahres 2021</b>
              entschieden. Alex fährt das Rad in 54 cm Rahmengröße, Vanessa in 47 cm. Mit einem zulässigen Gesamtgewicht
              von 150 Kilogramm, einem stabilen Aluminumrahmen und vormontierten Lowridern zählt der World Traveller von
              Koga zu einem der Klassiker auf dem Markt der Reiseräder. Wichtig waren für uns die hydraulischen
              Scheibenbremsen, mit der das 2021er Modell erstmals in der Word Traveller Serie ausgestattet wurde. Das
              Rad verfügt über 30 Gänge durch die verbaute Shimano Deore XT T8000 3x10 Schaltgruppe. Die breiten,
              vormontierten Schwalbe Marathon Reifen haben einen extra Pannenschutz. Für mehr Spezifikationen könnt ihr
              gerne die aktuellsten Modelle auf der Website des Herstellers Koga ansehen.
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-img
              contain
              src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrrad.jpeg?alt=media&token=08f963fc-bbc2-44ab-a612-82ad5e72097b"
              max-height="500"
            ></v-img>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <p>
              Was wir bei der Probefahrt auf unserem Koga besonders geschätzt haben ist das Gefühl von großer
              Stabilität, mit der das Rad fährt. Das Fahrempfinden ist so sicher, als führe man auf Schienen…oder einen
              kleinen Panzer ;)
            </p>
            <p>
              Unglücklicherweise folgt Koga bei der Auswahl der Rahmenform Gender-Rollen aus dem letzten Jahrhundert und
              stellt Rahmen unter 50 cm nur in Trapezform her. Ein Trapezrahmen hat nicht nur das “Oma-Flair”, dass
              viele sportliche junge Frauen nur ungern wählen, sondern bieten auch deutlich weniger Möglichkeiten für
              die Montage von Flaschenhaltern und ähnlichem im Rahmen.
            </p>
            <p>
              Mit einem Neupreis von 2199 € lag der World Traveller gerade noch in unserem Budget, da viele Reiseräder
              in einer Preisklasse ab 3500 € angesiedelt sind. Seit unserem Kauf wurde der Neupreis allerdings
              inzwischen auf 2299 € angehoben. Diese Preissteigerung betrifft auch andere Räder, die wir favorisiert
              hatten. Wenn euch interessiert wie wir unsere Räder noch etwas aufgemotzt haben schaut gerne in unser
              YouTube Video dazu rein!
            </p>
            <div id="3" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              3. Die Alternativen
            </div>
            <p>
              Zugegeben, der World Traveller war nicht das Rad, dass wir von Anfang am im Auge hatten. Zunächst haben
              wir uns das
              <b>TX400 und TX800 von Fahrradmanufaktur</b>
              angesehen und wurden dabei auch schnell auf das
              <b>Cube Kathmandu SL</b>
              aufmerksam, alle in einem Preisrahmen von 1900 bis 2100 €. Da die Modelle leider nicht verfügbar waren,
              fuhren wir auch Fahrräder der Marke
              <b>Tout Terrain</b>
              Probe, die uns persönlich allerdings nicht zugesagt haben. Von anderen Radreisenden wird häufig die Firma
              <b>Böttcher</b>
              mit dem
              <b>Böttcher Evolution</b>
              genannt, doch da die Firma ihren Sitz in Norddeutschland hat waren keine Händler in unserer (zugegeben auf
              4 Bundesländer ausgeweiteten) Region, die diese Räder führen.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>1. Wie wir zu unseren Rädern kamen</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Der Koga World Traveller</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Die Alternativen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
