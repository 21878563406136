<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FIMG_8480.jpeg?alt=media&token=4ff5bc2f-6ac5-4270-8666-945a80226e59"
          max-height="450"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Die Fahrradtaschen</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">29. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>1. Unsere Fahrradtaschen von Ortlieb</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Unsere Fahrradtaschen von Thule</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" class="pr-3">
        <v-row no-gutters>
          <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
            1. Unsere Fahrradtaschen von Ortlieb
          </div>
          <p>
            Viele setzten bei den Taschen auf den Marktführer Ortlieb. Der hat neben einer hohen Qualität auch hohe
            Preise - daher haben wir unserer Taschen von Ortlieb gebraucht gekauft. Beide haben wir die Lenkertasche
            Ultimate in 7 Litern Volumen sowie ein Rack Pack mit 31 Litern. Wir haben diese auf ebay Kleinanzeigen für
            circa die Hälfte des Neupreises gekauft. Natürlich nicht mehr brandneu, aber bei einer Fahrradreise bleibt
            sowieso nichts lange frisch ;)
          </p>
          <v-col cols="12">
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Fortlieb_front.jpeg?alt=media&token=eee6095e-1634-473e-a9f7-ef7ed3e5996c"
              max-height="350"
            ></v-img>
          </v-col>
          <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
            2. Unsere Fahrradtaschen von Thule
          </div>
          <p>
            Uns persönlich gefällt das Design und vor allem das Material der Ortlieb Fahrradtaschen nicht so gut, daher
            haben wir bei den 4 Taschen für die Gepäckträger auf Thule gesetzt. Die Taschen von Thule sind kaum bekannt,
            überzeugen aber mit robustem Material und guter Verarbeitung. Bisher sind wir sehr zufrieden. Die hinteren
            Modelle haben jeweils 25 Liter, die vorderen 13 Liter. Preislich haben wir sie über eine Sonderaktion etwa
            zur Hälfte des Preises der Ortlieb-Pendants bekommen und haben für alle 4 Taschen 130 € bezahlt. Insgesamt
            haben wir somit 114 Liter zur Verfügung.
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="px-2">
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Fthule_closeup_2.jpeg?alt=media&token=f37ca27c-aefb-42c2-a2f2-11e0bdc36268"
              max-height="300"
            ></v-img>
          </v-col>
          <v-col cols="6" class="px-2">
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Fthule_fron_closeup.jpeg?alt=media&token=2d21bd41-28a6-4282-b52d-22f57f7a8019"
              max-height="300"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>1. Unsere Fahrradtaschen von Ortlieb</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Unsere Fahrradtaschen von Thule</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
