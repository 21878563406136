import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#495867",
        secondary: "#577399",
        accent: "#BDD5EA",
        error: "#FE5F55",
      },
    },
  },
});
