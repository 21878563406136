<template>
  <v-container>
    <v-row>
      <v-col>
        <v-img
          class="white--text align-end"
          :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
          src="@/assets/banner.png"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-calendar-range</v-icon>
          <p class="text-body-1">
            We were on the road for
            <b>113 days</b>
          </p>
        </v-layout>
      </v-col>
      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-map</v-icon>
          <p class="text-body-1">
            <b>{{ this.statistics.km_gesamt }} km</b>
            of covered distance
          </p>
        </v-layout>
      </v-col>

      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-map-marker-distance</v-icon>
          <p class="text-body-1">
            <b>{{ this.statistics.km_durchschnitt }} km</b>
            Average distance per day
          </p>
        </v-layout>
      </v-col>
      <v-col cols="12" md="6">
        <v-layout class="mx-2">
          <v-icon x-large class="pr-2 pb-1">mdi-elevation-rise</v-icon>
          <p class="text-body-1">
            <b>{{ this.statistics.hoehenmeter_gesamt }}</b>
            meters of altitude
          </p>
        </v-layout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout justify-center>
          <v-btn to="statistiken" depressed color="primary">More Statistics</v-btn>
        </v-layout>
      </v-col>
    </v-row>

    <v-row class="mb-12">
      <v-col lg="6">
        <v-card to="karte" class="mx-auto" :height="$vuetify.breakpoint.xs ? '250px' : '410px'">
          <v-img
            class="white--text align-end"
            :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
            src="@/assets/img/home/openstreetmap.png"
          ></v-img>
          <v-card-title style="word-break: break-word">You want to know where we are?</v-card-title>
          <v-card-text class="text--primary py-0">
            <div>Here you can find an interactive map with our travel route</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card href="https://www.youtube.com/watch?v=qu_MBKwAse0">
          <div><youtube fit-parent video-id="qu_MBKwAse0" ref="youtube"></youtube></div>
          <v-card-title class="py-0" style="word-break: break-word">Want to see more?</v-card-title>
          <v-card-text class="text--primary my-2">
            <div>Here you can find our latest YouTube Video</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-12">
      <v-col lg="6">
        <v-card to="fahrrad" class="mx-auto" :height="$vuetify.breakpoint.xs ? '250px' : '410px'">
          <v-img
            class="white--text align-end"
            :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
            src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrraeder_Alex.jpeg?alt=media&token=a471a055-63c2-4f22-8892-4940d2e0c0ef"
          ></v-img>
          <v-card-title style="word-break: break-word">Interested in our bikes?</v-card-title>
          <v-card-text class="text--primary py-0" style="height: 44px">
            <div>Our Koga Worldtraveller in detail</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card to="ausruestung" class="mx-auto" :height="$vuetify.breakpoint.xs ? '250px' : '410px'">
          <v-img
            class="white--text align-end"
            :height="$vuetify.breakpoint.xs ? '100px' : '300px'"
            src="@/assets/img/home/ausruestung_card.jpg"
          ></v-img>
          <v-card-title style="word-break: break-word">Here you can find our Equipment</v-card-title>
          <v-card-text class="text--primary py-0" style="height: 44px">
            <div>A detailed list of all our equipment</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-2 mt-12">
      <div class="text-h4 text-center">About us</div>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="justify-center d-flex">
        <v-img contain max-height="400" src="@/assets/img/home/Bild_home.jpeg"></v-img>
      </v-col>
      <v-col cols="12" lg="6" class="justify-center d-flex">
        <p>
          We are Vanessa and Alex, two geoinformatics specialists from southern Germany, who quit their jobs to pursue
          their love of adventure. For a long time we thought about how we want to travel and have decided for cost but
          especially for ecological reasons for the bicycle as a means of transportation. And that, although we both
          (still 😉) do not understand much about bikes! We would be happy if you accompany us on our journey, on this
          website or on YouTube and Instagram.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statistics_json from "../../json/statistics.json";
export default {
  data() {
    return { days_til_start_date: null, days_since_start_date: null, statistics: statistics_json };
  },
  mounted() {
    //Caclulate start date
    let today = new Date();
    let start_date = new Date("06/06/2022");
    var one_day = 1000 * 60 * 60 * 24;
    this.days_til_start_date = Math.ceil((start_date.getTime() - today.getTime()) / one_day);
    this.days_since_start_date = Math.abs(this.days_til_start_date) + 1;
  },
};
</script>
