<template>
  <div><ArbeitslosigkeitDE v-if="locale == 'de'" /> <NoTranslation v-if="locale == 'en'" /></div>
</template>

<script>
import ArbeitslosigkeitDE from "@/components/de/reiseplanung/ArbeitslosigkeitDE.vue";
import NoTranslation from "@/components/en/NoTranslation.vue";
export default {
  name: "Arbeitslosigkeit",
  components: {
    ArbeitslosigkeitDE,
    NoTranslation,
  },
  data() {
    return {
      locale: null,
    };
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },

  watch: {
    "$i18n.locale": function (newVal) {
      this.locale = newVal;
    },
  },
};
</script>
