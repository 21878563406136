<template>
  <v-container fluid pa-6>
    <v-row>
      <v-col cols="12" class="justify-center d-flex">
        <div class="text-h2">404</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="justify-center d-flex">
        <v-img contain max-height="200" src="@/assets/404.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="justify-center d-flex">
        <h3>{{ $t("404.text") }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="justify-center d-flex">
        <router-link to="/">
          <div>{{ $t("404.back") }}</div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
