<template>
  <v-container fluid pa-8 pa-md-16>
    <ImpressumDE v-if="locale == 'de'" />
    <ImpressumEN v-if="locale == 'en'" />
  </v-container>
</template>

<script>
import ImpressumDE from "@/components/de/ImpressumDE.vue";
import ImpressumEN from "@/components/en/ImpressumEN.vue";
export default {
  name: "AppImpressum",
  components: {
    ImpressumDE,
    ImpressumEN,
  },
  data() {
    return {
      locale: null,
    };
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },

  watch: {
    "$i18n.locale": function(newVal) {
      this.locale = newVal;
    },
  },
};
</script>
