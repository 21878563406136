var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","value":this.AppBar,"id":"app-bar"},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"optional":true}},[_c('v-tab',{attrs:{"to":"/"}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-tab',{attrs:{"to":"/karte"}},[_vm._v(_vm._s(_vm.$t("menu.karte")))]),_c('v-tab',{attrs:{"to":"/fahrrad"}},[_vm._v(_vm._s(_vm.$t("menu.fahrrad")))]),_c('v-menu',{attrs:{"open-on-hover":!_vm.touch,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("menu.ausruestung"))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2540062954)},[_c('v-list',{staticClass:"grey lighten-3"},[_c('v-list-item',{attrs:{"to":"/ausruestung/camping"}},[_vm._v(" "+_vm._s(_vm.$t("menu.camping"))+" ")]),_c('v-list-item',{attrs:{"to":"/ausruestung/technik"}},[_vm._v(" "+_vm._s(_vm.$t("menu.technik"))+" ")]),_c('v-list-item',{attrs:{"to":"/ausruestung/taschen"}},[_vm._v(" "+_vm._s(_vm.$t("menu.taschen"))+" ")]),_c('v-list-item',{attrs:{"to":"/ausruestung/werkzeug"}},[_vm._v(" "+_vm._s(_vm.$t("menu.werkzeug"))+" ")]),_c('v-list-item',{attrs:{"to":"/ausruestung/kleidung"}},[_vm._v(" "+_vm._s(_vm.$t("menu.kleidung"))+" ")])],1)],1),_c('v-menu',{attrs:{"open-on-hover":!_vm.touch,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("menu.reiseplanung"))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,4123243667)},[_c('v-list',{staticClass:"grey lighten-3"},[_c('v-list-item',{attrs:{"to":"/reiseplanung/allgemeines"}},[_vm._v(" "+_vm._s(_vm.$t("menu.allgemein"))+" ")]),_c('v-list-item',{attrs:{"to":"/reiseplanung/verkehrsregeln"}},[_vm._v(" "+_vm._s(_vm.$t("menu.verkehrsregeln"))+" ")]),_c('v-list-item',{attrs:{"to":"/reiseplanung/reisemedizin"}},[_vm._v(" "+_vm._s(_vm.$t("menu.medizin"))+" ")]),_c('v-list-item',{attrs:{"to":"/reiseplanung/versicherungen"}},[_vm._v(" "+_vm._s(_vm.$t("menu.versicherungen"))+" ")]),_c('v-list-item',{attrs:{"to":"/reiseplanung/arbeitslosigkeit"}},[_vm._v(" "+_vm._s(_vm.$t("menu.arbeitslosigkeit"))+" ")]),_c('v-list-item',{attrs:{"to":"/reiseplanung/finanzierung"}},[_vm._v(" "+_vm._s(_vm.$t("menu.finanzierung"))+" ")])],1)],1),_c('v-tab',{attrs:{"to":"/statistiken"}},[_vm._v(_vm._s(_vm.$t("menu.statistiken")))])],1),_c('v-tabs',{attrs:{"right":"","hide-slider":""}},[_c('v-tab',{staticClass:"px-1",attrs:{"ripple":false,"id":"no-background-hover"}},[_c('v-btn',{attrs:{"href":"https://www.youtube.com/channel/UCJ9mY8_S815wB6LUsa81mHQ","target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-youtube")])],1),_c('v-btn',{attrs:{"href":"https://www.instagram.com/biking_beyond/","target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-instagram")])],1)],1),_c('v-tab',{staticClass:"px-1",attrs:{"ripple":false,"id":"no-background-hover"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('img',{attrs:{"width":"20","src":_vm.currentLanguage.flagSrc}})])]}}],null,false,3915892705)},[_c('v-list',_vm._l((_vm.languages),function(language){return _c('v-list-item',{key:language.id,on:{"click":function($event){return _vm.changeLanguage(language.id)}}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"24"}},[_c('v-img',{attrs:{"src":language.flagSrc}})],1),_c('v-list-item-title',[_vm._v(_vm._s(language.title))])],1)}),1)],1)],1)],1)]},proxy:true}:null],null,true)},[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$emit('update:drawer', !_vm.drawer)}}}):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('img',{attrs:{"width":"20","src":_vm.currentLanguage.flagSrc}})])]}}],null,false,3915892705)},[_c('v-list',_vm._l((_vm.languages),function(language){return _c('v-list-item',{key:language.id,on:{"click":function($event){return _vm.changeLanguage(language.id)}}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"24"}},[_c('v-img',{attrs:{"src":language.flagSrc}})],1),_c('v-list-item-title',[_vm._v(_vm._s(language.title))])],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }