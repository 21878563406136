<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col sm="6" md="5" lg="4" xl="3" class="fill-parent-height" v-if="$vuetify.breakpoint.smAndUp">
        <CardsDesktop :gpxCollection="gpxCollection_filtered" :gpx_id.sync="gpx_id" />
      </v-col>
      <v-col sm="6" md="7" lg="8" xl="9" :class="calc_map_class">
        <v-progress-circular
          v-if="!finshed_drawing"
          indeterminate
          size="120"
          width="6"
          style="z-index: 1"
          class="loadingIcon text-overline font-weight-bold"
          color="primary"
        >
          <div class="d-flex flex-column justify-center" style="-webkit-text-stroke: 0.1px white">
            <p class="d-flex justify-center ma-0 pa-0">{{ this.loading_message }}</p>
            <p class="d-flex justify-center ma-0 pa-0">gpx routes</p>

            <p class="d-flex justify-center ma-0 pa-0">{{ this.counter }}/{{ this.counter_length }}</p>
          </div>
        </v-progress-circular>
        <l-map ref="Map" style="z-index: 0" :zoom="zoom" :center="center" :options="{ zoomControl: false }">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <GpxRoute
            v-for="(gpx, index) in gpxCollection"
            :key="index"
            :gpx="gpx"
            :loaded="loaded"
            @counter="addToCounter"
          />
          <l-control-zoom v-if="$vuetify.breakpoint.smAndUp" position="bottomright"></l-control-zoom>
          <l-control v-if="$vuetify.breakpoint.xsOnly" position="bottomright" class="leaflet-bar card-menu">
            <button @click="toggleCards">
              <v-icon class="ma-1">mdi-routes</v-icon>
            </button>
          </l-control>
        </l-map>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showCards" class="grey lighten-4 cards-mobile">
      <v-col cols="12"><CardsMobile :gpxCollection="gpxCollection_filtered" :gpx_id.sync="gpx_id" /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import GpxRoute from "@/components/map/GpxRoute.vue";
import CardsDesktop from "@/components/map/CardsDesktop.vue";
import CardsMobile from "@/components/map/CardsMobile.vue";
import { LMap, LTileLayer, LControlZoom, LControl } from "vue2-leaflet";
import "leaflet-gpx";
import "leaflet/dist/leaflet.css";

import { db } from "../main";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  name: "AppKarte",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
    GpxRoute,
    CardsDesktop,
    CardsMobile,
  },
  data() {
    return {
      gpxCollection: [],
      data: [],
      map: {},
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      center: [41, 9],
      markerLatLng: [51.504, -0.159],
      loadingValue: 0,
      loaded: false,
      finshed_drawing: false,
      showCards: false,
      gpx_id: 0,
      counter: 0,
      counter_length: "",
      loading_message: "Loading",
    };
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    this.$nextTick(() => {
      this.map = this.$refs.Map.mapObject;
    });
    db.collection("gpx")
      .get()
      .then((querySnapshot) => {
        const storage = getStorage();
        const documents = querySnapshot.docs.map((doc) => doc.data());

        this.gpxCollection = documents;
        let that = this;

        let length = documents.length;
        this.counter_length = length;

        documents.forEach(function (document, index) {
          getDownloadURL(ref(storage, document.gpx_url))
            .then((url) => {
              const xhr = new XMLHttpRequest();
              xhr.responseType = "text";
              xhr.open("GET", url);
              xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                  if (this.status === 200) {
                    that.gpxCollection[index].gpxFile = xhr.response;
                    that.counter++;
                    if (that.counter === length) {
                      that.loaded = true;
                      that.loading_message = "Drawing";
                      that.counter = 0;
                    }
                  } else {
                    console.log(this.status, this.statusText);
                  }
                }
              };
              xhr.send();
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
  },
  methods: {
    addToCounter(value) {
      this.counter += value[1];
      if (this.counter == this.counter_length) {
        this.finshed_drawing = true;
      }
    },
    toggleCards() {
      this.showCards = !this.showCards;
    },
    set_gpx_id(gpx_id) {
      this.gpx_id = parseInt(gpx_id);
      if (this.$vuetify.breakpoint.xsOnly) {
        this.showCards = true;
      }
      if (!this.$vuetify.breakpoint.xsOnly) {
        document.getElementById("gpx_track_id_" + gpx_id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    getWeatherIcon(weather) {
      let icon = null;
      if (weather == "sunny") {
        icon = "mdi-white-balance-sunny";
      } else if (weather == "rainy") {
        icon = "mdi-weather-pouring";
      } else if (weather == "cloudy") {
        icon = "mdi-weather-cloudy";
      } else if (weather == "storm") {
        icon = "mdi-weather-windy-variant";
      } else if (weather == "snow") {
        icon = "mdi-snowflake";
      } else if (weather == "partly-cloudy") {
        icon = "mdi-weather-partly-cloudy";
      } else if (weather == "partly-rainy") {
        icon = "mdi-weather-partly-rainy";
      }
      return icon;
    },
    getWeatherDescription(weather) {
      let description = null;
      if (weather == "sunny") {
        description = "map.weather.sunny";
      } else if (weather == "rainy") {
        description = "map.weather.rainy";
      } else if (weather == "cloudy") {
        description = "map.weather.cloudy";
      } else if (weather == "storm") {
        description = "map.weather.storm";
      } else if (weather == "snow") {
        description = "map.weather.snow";
      } else if (weather == "partly-cloudy") {
        description = "map.weather.partly_cloudy";
      } else if (weather == "partly-rainy") {
        description = "map.weather.partly_rainy";
      }
      return description;
    },
    getStayTypeIcon(type) {
      let icon = null;
      if (type == "zelten" || type == "wildzelten") {
        icon = "mdi-tent";
      } else if (type == "hotel") {
        icon = "mdi-bed";
      }
      return icon;
    },
    getStayTypeDescription(type) {
      let description = null;
      if (type == "zelten" || type == "wildzelten") {
        description = "map.stay_type.zelten";
      } else if (type == "hotel") {
        description = "map.stay_type.hotel";
      }
      return description;
    },
    getTrackDescription(type) {
      let description = null;
      if (type == "rest-day") {
        description = "map.description.rest-day";
      } else if (type == "flight") {
        description = "map.description.flight";
      } else if (type == "train") {
        description = "map.description.train";
      } else if (type == "ferry") {
        description = "map.description.ferry";
      }
      return description;
    },
    msToHMS(duration) {
      let minutes = parseInt((duration / (1000 * 60)) % 60);
      let hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      return hours + ":" + minutes;
    },
  },
  computed: {
    gpxCollection_filtered() {
      return this.gpxCollection.filter((item) => item.show_in_cards === "true");
    },
    calc_map_class() {
      if (this.$vuetify.breakpoint.xsOnly && this.showCards) {
        return "fill-remaining";
      } else if (this.$vuetify.breakpoint.xsOnly && this.showCards == false) {
        return "fill-parent-height-small";
      } else {
        return "fill-parent-height";
      }
    },
  },
};
</script>

<style>
.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
html {
  overflow-y: auto;
}

.fill-parent-height {
  height: calc(100vh - 92px);
  overflow-y: scroll;
}

.fill-parent-height-small {
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100) - 56px);
}

.fill-remaining {
  height: calc((var(--vh, 1vh) * 100) - 276px);
}

.card-menu {
  background-color: #fff;
}

.cards-mobile {
  overflow-y: scroll;
  height: 300px;
}
</style>
