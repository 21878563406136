<template>
  <v-app>
    <AppMenu :drawer.sync="drawer" />
    <DrawerMobile :drawer.sync="drawer" />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AppMenu from "@/components/app/AppMenu.vue";
import DrawerMobile from "@/components/app/DrawerMobile.vue";
import Footer from "@/components/app/Footer.vue";
export default {
  name: "App",
  components: { AppMenu, DrawerMobile, Footer },
  data: () => ({
    drawer: false,
  }),
};
</script>

<style lang="scss">
#no-background-hover::before {
  background-color: transparent !important;
}

html {
  overflow-y: auto;
}

.v-btn--active.no-active:not(:focus):not(:hover)::before {
  opacity: 0 !important;
}

.v-main {
  background-color: whitesmoke;
  line-height: 1.8;
  font-size: large;
}

@media (max-width: 540px) {
  .v-main {
    background-color: white;
  }
}

.main-content {
  background-color: white;
}

.v-list-item__content {
  white-space: normal !important;
}

.v-list-item__title {
  white-space: normal !important;
}

.v-list-item__icon.v-list-group__header__append-icon {
  min-width: 20px !important;
}

.v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}

.v-card__title {
  word-break: break-word !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
