<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="text-h4 text-center py-6">
          Allgemeines zur Reiseplanung
        </div></v-col
      >
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"
            ><img src="@/assets/Avatar_Vanessa.jpg"
          /></v-avatar>
          <p class="text--disabled text-overline text-left">29. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>Allgemeines zur Reiseplanung</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="9"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
      >
        <v-row no-gutters>
          <div
            id="1"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            Allgemeines zur Reiseplanung
          </div>
          <p>
            Wir haben einige Informationen zur Reiseplanung zusammengetragen und
            versucht die Informationen bestmöglich für Dich aufzubereiten. Um
            Dir den Planungsprozess einfacher zu machen, haben wir einige
            Checklisten und Templates zum kostenlosen Download entworfen.
          </p>
          <p>
            Um mehr Details zu den einzelnen Kategorien zu erhalten schau doch
            gerne einmal hier nach:
          </p>
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="0" md="0" lg="1" xl="2"></v-col>
              <v-col
                cols="12"
                md="12"
                lg="10"
                xl="8"
                class="main-content rounded-lg pa-2 pa-md-4"
              >
                <v-row no-gutters>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/verkehrsregeln" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title
                          >Verkehrsregeln für Radfahrer in Europa</v-card-title
                        >
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col sm="6">
                    <v-card to="/reiseplanung/reisemedizin" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Reisemedizin und Impfungen</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/versicherungen" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Versicherungen</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/arbeitslosigkeit" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Arbeitslos melden?</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col sm="6">
                    <v-card to="/reiseplanung/finanzierung" class="ma-2">
                      <v-img
                        height="120"
                        class="clack--text align-center"
                        src="@/assets/img/raw_background.png"
                      >
                        <v-card-title>Kostenkalkulation</v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col sm="6"></v-col>
                </v-row>
              </v-col>
              <v-col cols="0" md="0" lg="1" xl="2"></v-col>
            </v-row>
          </v-container>
          <v-alert outlined type="warning" elevation="1">
            Wir haben alle Informationen nach bestem Wissen und Gewissen
            erstellt. Bitte informiere dich aber auch immer selbst. Wenn du
            Fehler entdeckst kannst du uns diese gerne mit einem Nachweis oder
            einer Quelle per Mail an
            <a href="mailto:mail@bikingbeyond.de">mail@bikingbeyond.de</a>
            an uns melden.
          </v-alert>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="0"
        md="3"
        class="d-flex flex-column align-end justify-start"
      >
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title
                >Allgemeines zur Reiseplanung</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
