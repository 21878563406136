<template>
  <v-carousel height="1100px" v-model="init_gpx_id" class="pa-2" hide-delimiters>
    <v-carousel-item v-for="item in reversedGpxItems" :key="item.id">
      <v-card :height="calcCardHeight(item)" elevation="2" outlined shaped tile>
        <v-container fluid>
          <v-row align="start" no-gutters>
            <v-col cols="12" class="pb-2">
              <v-card-title class="justify-center pa-0">
                {{ $t("map.tag") }} {{ item.title }}
                <v-icon dense class="ml-3" @click="zoomToGPX(item.id)">mdi-map-search</v-icon>
              </v-card-title>
              <v-card-subtitle class="text-center pb-0 grey--text pt-4">
                {{ getDates(item.date, item.date_end) }}
              </v-card-subtitle>
              <v-card-subtitle class="text-center pb-0 grey--text pt-0">
                {{ $t($parent.getTrackDescription(item.description)) }}
              </v-card-subtitle>
            </v-col>
            <v-col cols="6" align="left" justify="center" class="pl-4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">mdi-map-marker-distance</v-icon>
                    {{ item.distance }} km
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">mdi-elevation-rise</v-icon>
                    {{ item.elevation_gain }} m
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">{{ $parent.getStayTypeIcon(item.stay_type) }}</v-icon>
                    {{ $t($parent.getStayTypeDescription(item.stay_type)) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" align="left" justify="center" class="pl-4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">mdi-speedometer</v-icon>
                    {{ item.moving_speed }}
                    km/h
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">mdi-timer-outline</v-icon>
                    {{ item.moving_time }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon class="pr-2 pb-1">{{ $parent.getWeatherIcon(item.weather) }}</v-icon>
                    {{ $t($parent.getWeatherDescription(item.weather)) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" align="center">
              <v-icon v-if="item.youtube_id || item.instagram_id" style="top: -25px" id="scroll-down-prompt">
                mdi-arrow-down
              </v-icon>
            </v-col>
            <v-col cols="12" v-if="item.youtube_id">
              <div class="ma-2"><youtube fitParent :video-id="item.youtube_id" ref="youtube"></youtube></div>
            </v-col>
            <v-col cols="12" v-if="item.instagram_id">
              <InstagramPost :post_id="item.instagram_id" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import InstagramPost from "@/components/map/InstagramPost.vue";
export default {
  props: {
    gpxCollection: { type: Array, default: () => ({}) },
    gpx_id: {
      type: Number,
    },
  },
  components: {
    InstagramPost,
  },
  data() {
    return { init_gpx_id: this.gpx_id };
  },
  mounted() {
    this.map = this.$parent.$refs.Map.mapObject;
  },

  methods: {
    // On function call  zooms to the layer which layerID matches the current gpx_id
    zoomToGPX(gpx_id) {
      let that = this;
      console.log(gpx_id);
      this.map.eachLayer(function (layer) {
        if (layer.layerID == gpx_id) {
          console.log(layer);
          // reset map bounds
          that.map.invalidateSize();
          // try catch in case gpy does´t have bounds
          try {
            that.map.flyToBounds(layer.getBounds());
          } catch {
            let lat = layer._latlng.lat;
            let lng = layer._latlng.lng;
            that.map.flyTo([lat, lng], 13);
          }
        }
      });
    },
    calcCardHeight(item) {
      if (item.youtube_id) {
        return "450px";
      } else if (item.instagram_id) {
        return "1100px";
      } else {
        return "210px";
      }
    },
    getDates(date, date_end) {
      if (date == date_end) {
        return this.formatDate(date);
      } else {
        return this.formatDate(date) + " - " + this.formatDate(date_end);
      }
    },
    formatDate(input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];

      return day + "." + month + "." + year;
    },
  },
  computed: {
    reversedGpxItems() {
      return this.gpxCollection.slice().reverse();
    },
  },
  watch: {
    gpx_id: function (value) {
      this.init_gpx_id = this.reversedGpxItems.findIndex((gpx) => gpx.id == value);
    },
  },
};
</script>

<style>
.v-window__prev {
  top: calc(50% - 535px);
}

.v-window__next {
  top: calc(50% - 535px);
}

#scroll-down-prompt {
  -webkit-animation: scrollprompt 1.5s infinite;
  animation: scrollprompt 1.5s infinite;
}
@-webkit-keyframes scrollprompt {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-0px, 20px);
    opacity: 0;
  }
}
@keyframes scrollprompt {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-0px, 20px);
    opacity: 0;
  }
}
</style>
