<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="0" md="0" lg="1" xl="2"></v-col>
      <v-col cols="12" md="12" lg="10" xl="8" class="main-content rounded-lg pa-2 pa-md-4">
        <v-row no-gutters>
          <v-col sm="6">
            <v-card to="ausruestung/camping" class="ma-2">
              <v-img
                height="210"
                class="white--text justify-center align-center"
                src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FAusruestung_Cooking_Overwiew.jpeg?alt=media&token=6e19e04f-ce8c-4db5-8882-6945be897f84"
              >
                <!-- <div
                  id="7"
                  class="text-h3 text-center align-center justify-center d-flex white--text"
                  style="text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
                >
                  Camping
                </div> -->
              </v-img>
              <v-card-title style="word-break: break-word" class="justify-center">
                {{ $t("menu.camping") }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col sm="6">
            <v-card to="ausruestung/technik" class="ma-2">
              <v-img
                height="210"
                class="white--text align-end"
                src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FAusruestung_Technik_Overwiew.jpeg?alt=media&token=c6def44a-f7ee-4b8d-a76d-ff5cac0b9e0a"
              ></v-img>
              <v-card-title style="word-break: break-word" class="justify-center">
                {{ $t("menu.technik") }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col sm="6">
            <v-card to="ausruestung/taschen" class="ma-2">
              <v-img
                height="210"
                class="white--text align-end"
                src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FFahrraeder_Alex.jpeg?alt=media&token=a471a055-63c2-4f22-8892-4940d2e0c0ef"
              ></v-img>
              <v-card-title style="word-break: break-word" class="justify-center">
                {{ $t("menu.taschen") }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col sm="6">
            <v-card to="ausruestung/kleidung" class="ma-2">
              <v-img
                height="210"
                class="white--text align-end"
                src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FVanessa_Waesche.jpeg?alt=media&token=5f4ce4a2-69b5-47d4-b137-3850c1ccdf87"
              ></v-img>
              <v-card-title style="word-break: break-word" class="justify-center">
                {{ $t("menu.kleidung") }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col sm="3"></v-col>
          <v-col sm="6">
            <v-card to="ausruestung/werkzeug" class="ma-2">
              <v-img
                height="210"
                class="white--text align-end"
                src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FAusruestung_Ersatzteile_Overview.jpeg?alt=media&token=6c2a3712-539b-4998-ae6b-2805ba39f932"
              ></v-img>
              <v-card-title style="word-break: break-word" class="justify-center">
                {{ $t("menu.werkzeug") }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col sm="3"></v-col>
        </v-row>
      </v-col>
      <v-col cols="0" md="0" lg="1" xl="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Ausruestung",
  components: {},
  data() {
    return {
      locale: null,
    };
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },

  watch: {
    "$i18n.locale": function (newVal) {
      this.locale = newVal;
    },
  },
};
</script>
