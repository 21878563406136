import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import VueGtag from "vue-gtag";
import VueYoutube from "vue-youtube";
import VueNumber from "vue-number-animation";
import VueCompositionAPI from "@vue/composition-api";

const firebaseConfig = {
  apiKey: "AIzaSyDoWG5x0XZvTY3t-9eEOdH1V7fbYUDpKrs",
  authDomain: "biking-beyond.firebaseapp.com",
  projectId: "biking-beyond",
  storageBucket: "biking-beyond.appspot.com",
  messagingSenderId: "974110212747",
  appId: "1:974110212747:web:c531b8451da9fd18510a8e",
  measurementId: "G-1KG39B1XTB",
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

Vue.use(
  VueGtag,
  {
    config: { id: "G-1KG39B1XTB" },
    enabled: false,
  },
  router
);

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.use(VueYoutube);
Vue.use(VueNumber);

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
