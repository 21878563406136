<template>
  <v-app-bar app color="primary" dark :value="this.AppBar" id="app-bar">
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xsOnly" @click="$emit('update:drawer', !drawer)"></v-app-bar-nav-icon>
    <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp">
      <v-tabs :optional="true">
        <v-tab to="/"><v-icon>mdi-home</v-icon></v-tab>
        <v-tab to="/karte">{{ $t("menu.karte") }}</v-tab>
        <v-tab to="/fahrrad">{{ $t("menu.fahrrad") }}</v-tab>
        <v-menu :open-on-hover="!touch" offset-y>
          <template v-slot:activator="{ on }">
            <v-tab v-on="on">
              {{ $t("menu.ausruestung") }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-tab>
          </template>
          <v-list class="grey lighten-3">
            <v-list-item to="/ausruestung/camping">
              {{ $t("menu.camping") }}
            </v-list-item>
            <v-list-item to="/ausruestung/technik">
              {{ $t("menu.technik") }}
            </v-list-item>
            <v-list-item to="/ausruestung/taschen">
              {{ $t("menu.taschen") }}
            </v-list-item>
            <v-list-item to="/ausruestung/werkzeug">
              {{ $t("menu.werkzeug") }}
            </v-list-item>
            <v-list-item to="/ausruestung/kleidung">
              {{ $t("menu.kleidung") }}
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu :open-on-hover="!touch" offset-y>
          <template v-slot:activator="{ on }">
            <v-tab v-on="on">
              {{ $t("menu.reiseplanung") }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-tab>
          </template>
          <v-list class="grey lighten-3">
            <v-list-item to="/reiseplanung/allgemeines">
              {{ $t("menu.allgemein") }}
            </v-list-item>
            <v-list-item to="/reiseplanung/verkehrsregeln">
              {{ $t("menu.verkehrsregeln") }}
            </v-list-item>
            <v-list-item to="/reiseplanung/reisemedizin">
              {{ $t("menu.medizin") }}
            </v-list-item>
            <v-list-item to="/reiseplanung/versicherungen">
              {{ $t("menu.versicherungen") }}
            </v-list-item>
            <v-list-item to="/reiseplanung/arbeitslosigkeit">
              {{ $t("menu.arbeitslosigkeit") }}
            </v-list-item>
            <v-list-item to="/reiseplanung/finanzierung">
              {{ $t("menu.finanzierung") }}
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tab to="/statistiken">{{ $t("menu.statistiken") }}</v-tab>
      </v-tabs>
      <v-tabs right hide-slider>
        <v-tab :ripple="false" id="no-background-hover" class="px-1">
          <v-btn href="https://www.youtube.com/channel/UCJ9mY8_S815wB6LUsa81mHQ" target="_blank" icon>
            <v-icon>mdi-youtube</v-icon>
          </v-btn>
          <v-btn href="https://www.instagram.com/biking_beyond/" target="_blank" icon>
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-tab>
        <v-tab :ripple="false" id="no-background-hover" class="px-1">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <img width="20" :src="currentLanguage.flagSrc" />
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="language in languages" :key="language.id" @click="changeLanguage(language.id)">
                <v-list-item-avatar tile size="24">
                  <v-img :src="language.flagSrc"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ language.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tab>
      </v-tabs>
    </template>
    <v-spacer></v-spacer>
    <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <img width="20" :src="currentLanguage.flagSrc" />
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="language in languages" :key="language.id" @click="changeLanguage(language.id)">
          <v-list-item-avatar tile size="24">
            <v-img :src="language.flagSrc"></v-img>
          </v-list-item-avatar>
          <v-list-item-title>{{ language.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      AppBar: false,
      touch: "",
      languages: [
        {
          id: "de",
          title: "Deutsch",
          flagSrc: require("@/assets/de.png"),
        },
        {
          id: "en",
          title: "English",
          flagSrc: require("@/assets/gb.png"),
        },
      ],
      currentLanguage: {},
    };
  },
  beforeMount() {
    this.touch = matchMedia("(hover: none), (pointer: coarse)").matches;
  },
  methods: {
    showAppBar() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.AppBar = true;
      }
    },
    changeLanguage(id) {
      this.$i18n.locale = id;
      this.currentLanguage = this.languages.find((language) => language.id == id);
    },
  },
  created() {
    this.showAppBar();
    this.currentLanguage = this.languages.find((language) => language.id == this.$i18n.locale);
  },
};
</script>

<style></style>
