<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FVanessa_Haengematte.jpeg?alt=media&token=63de84ae-6e04-47c3-8f99-2ed205057473"
          max-height="400"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Campingausrüstung</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">15. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>1. Zelt</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Schlafsystem</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Küchensetup</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(4)">
            <v-list-item-title>4. Luxusgegenstände</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''">
        <v-row no-gutters>
          <v-col>
            <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
              1. Zelt
            </div>
            <p>
              Von vergangenen Campingtrips, Wanderurlauben und einem Camino Portugues haben wir gelernt: ein Zelt mit
              1,20 Metern Breite ist zu klein für 2 Personen. Im Gegenteil, wir brauchen Platz: wenn es regnet und man
              all sein Equipment unterbringen will, um in Ruhe zu schlafen oder auch mal um ein paar Stunden am Abend
              vor Moskitos fliehend im Zelt zu faulenzen. Platz ist Gold wert. Wir suchten für uns zwei also nach einem
              leichten 3 Personen-Kuppelzelt. Von einer Freundin kannten wir Big Agnes, da ein Big Agnes auf dem Pacific
              Crest Trail ihr treuer Begleiter war. Wir haben uns daher für das Blacktail 3 in grün entschieden. Mit
              gerade einmal 2.58 kg aber Innenmaßen von 2.25 x 1.75 x 1.10 und 2 Apsiden ist es genau das was wir
              gesucht haben. Um es ein bisschen besser zu schützen nutzen wir eine zusätzliche Zeltunterlage.
            </p>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Ftent.jpeg?alt=media&token=da67c05b-b076-4154-a8bc-e9364b32be6c"
                  max-height="500"
                ></v-img>
              </v-col>
            </v-row>
            <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              2. Schlafsystem
            </div>
            <p>
              Unsere selbstaufblasenden Isomatten Kalla 5.0 von Frilufts isolieren gut, knistern nicht, sind nicht
              rutschig und dabei noch ganze 5 cm dick. Als wir noch zu Fuß unterwegs waren nutzten wir die Therm-A-Rest
              NeoXAir Lite, die wirklich ein toller Begleiter ist wenn es auf jedes Gramm und ein kleines Packmaß
              ankommt. Damals haben wir allerdings gemerkt, dass man von zu dicken, glatten und kleinen Matten schnell
              runter rutscht. Daher ist auch die beinahe rechteckige Form der Kalla 5.0 neben der weichen Oberfläche
              genau das was wir gesucht haben und ein echter Tipp. Sie wiegt allerdings 910 Gramm und hat ein deutlich
              größeres Packmaß als die NeoAirXLite. Vanessa friert schnell und nutzt daher den relativ warmen
              3-Jahreszeiten Kunstfaser Schlafsack Lamina von Mountain Hardware mit einer Komforttemperatur von -1°C und
              ist mit dem Schlafsack bereits auf mehreren Touren auch im Sommer sehr zufrieden gewesen. Aufdecken kann
              man sich ja immer ;) Alex ist da nicht so zimperlich und hat einen Schlafsack von Frijlufts mit 5 °C
              Komforttemperatur. Zum Schutz der Schlafsäcke und für besonders kalte Nächte haben wir zusätzlich ein
              Inlet von Cocon dabei. In warmen Nächten kann man auch nur das Inlet als Decke nutzten. Unser Kissen ist
              das Sea to Summit Foamcore Pillow, das mit Schaumstoff gefüllt ist und sich auf 20 % seiner Größe
              zusammenrollen lässt. Das Kissen ist wirklich eine eindeutige Kaufempfehlung und jedes Gramm wert!
            </p>
            <div id="3" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              3. Küchensetup
            </div>
            <p>
              Wir haben mal nachgerechnet: wenn man sich jeden Abend eine schöne Mahlzeit und einen Tee kocht, und dann
              morgens auf seinen Kaffee und von Zeit zu Zeit warmen Porride nicht verzichten will braucht man so einiges
              an Gaskartuschen. Maximal alle 2 Wochen benötigt man Nachschub. Nicht überall auf der Welt sind die aber
              so leicht zu bekommen wie bei uns. Daher haben wir uns einen Kocher zugelegt, der mit so ziemlich allem
              klar kommt: der Primus OmniFuel 2. Wir betreiben ihn hauptsächlich mit Tankstellenbenzin. Das ist auch auf
              lange Sicht gerechnet deutlich günstiger als der Gasbetrieb mit Camping-Kartuschen und zusätzlich entfällt
              der viele Müll. Ein Nachteil des Betriebs mit Benzin ist allerdings, dass man zuerst lernen muss den
              Kocher richtig zu nutzten und dass der Brenner ziemlich laut ist.
            </p>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2Fstove.jpeg?alt=media&token=065a4a0b-0ba5-4a5e-9e05-ec528090f580"
                  max-height="400"
                ></v-img>
              </v-col>
            </v-row>
            <p>
              Uns waren 2 große Töpfe wichtig. Langfristig kann ich nicht mit einem 1 Liter Topf gut für 2 hungrige
              Fahrradfahrer kochen, aber größere Töpfe mit vertretbarem Gewicht zu finden ist nicht so einfach. Wir
              waren umso glücklicher unser Kochset von Optimus gefunden zu haben. Es besteht aus 2 Töpfen, einer Pfanne,
              Deckel und einem isolierenden Schutz. Natürlich haben wir auch Geschirr, ein klappbares Küchenmesser von
              Victorinox, ein Brett und je einen Thermobecher für Kaffee am Morgen dabei. Um den zu brühen haben wir uns
              für den nur 20 Gramm schweren Java Drip Kaffeefilter von GSI entschieden. Um den Abwasch zu erledigen
              nutzten wir eine faltbare Wanne von Ortlieb.
            </p>
            <v-row no-gutters>
              <v-col cols="12">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FAusruestung_Cooking_Overwiew.jpeg?alt=media&token=6e19e04f-ce8c-4db5-8882-6945be897f84"
                  max-height="500"
                ></v-img>
              </v-col>
            </v-row>
            <div id="4" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
              4. Luxusgegenstände
            </div>
            <p>
              Wir haben uns einige Luxusgegenstände mitgenommen, darunter einen ultra leicht Campingstuhl und eine
              Hängematte, um die Zeit die wir nicht im Sattel verbringen gemütlich zu machen. Der Helinox Chair Zero ist
              bekannt unter den Outdoorlern. Günstig ist er mit seinen 120 € nicht, dafür aber enorm leicht: gerade
              einmal 490 Gramm bringt er auf die Wage. Die ultraleicht Hängematte von Exped wiegt mit Aufhängesystem
              weniger als 400 Gramm und kann auch als Sonnenschutz abgespannt werden.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>1. Zelt</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Schlafsystem</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Küchensetup</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(4)">
              <v-list-item-title>4. Luxusgegenstände</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
