import Vue from "vue";
import VueRouter from "vue-router";
import AppHome from "../views/AppHome.vue";
import AppKarte from "../views/AppKarte.vue";
import AppImpressum from "../views/AppImpressum.vue";
import AppFahrrad from "../views/AppFahrrad.vue";
import Ausruestung from "../views/ausruestung/Ausruestung.vue";
import Camping from "../views/ausruestung/Camping.vue";
import Technik from "../views/ausruestung/Technik.vue";
import Taschen from "../views/ausruestung/Taschen.vue";
import Werkzeug from "../views/ausruestung/Werkzeug.vue";
import Kleidung from "../views/ausruestung/Kleidung.vue";
import Allgemein from "../views/reiseplanung/Allgemein.vue";
import Arbeitslosigkeit from "../views/reiseplanung/Arbeitslosigkeit.vue";
import Finanzierung from "../views/reiseplanung/Finanzierung.vue";
import Verkehrsregeln from "../views/reiseplanung/Verkehrsregeln.vue";
import Reisemedizin from "../views/reiseplanung/Reisemedizin.vue";
import Versicherungen from "../views/reiseplanung/Versicherungen.vue";
import AppStatistiken from "../views/AppStatistiken.vue";
import PageNotFound from "../components/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: AppHome,
  },
  {
    path: "/karte",
    name: "Karte",
    component: AppKarte,
  },
  {
    path: "/fahrrad",
    name: "Fahrrad",
    component: AppFahrrad,
  },
  {
    path: "/ausruestung",
    name: "Ausruestung",
    component: Ausruestung,
  },
  {
    path: "/ausruestung/camping",
    name: "Camping",
    component: Camping,
  },
  {
    path: "/ausruestung/technik",
    name: "Technik",
    component: Technik,
  },
  {
    path: "/ausruestung/taschen",
    name: "Taschen",
    component: Taschen,
  },
  {
    path: "/ausruestung/werkzeug",
    name: "Werkzeug",
    component: Werkzeug,
  },
  {
    path: "/ausruestung/kleidung",
    name: "Kleidung",
    component: Kleidung,
  },
  {
    path: "/reiseplanung/allgemeines",
    name: "Allgemein",
    component: Allgemein,
  },
  {
    path: "/reiseplanung/verkehrsregeln",
    name: "Verkehrsregeln",
    component: Verkehrsregeln,
  },
  {
    path: "/reiseplanung/arbeitslosigkeit",
    name: "Arbeitslosigkeit",
    component: Arbeitslosigkeit,
  },
  {
    path: "/reiseplanung/finanzierung",
    name: "Finanzierung",
    component: Finanzierung,
  },
  {
    path: "/reiseplanung/reisemedizin",
    name: "Reisemedizin",
    component: Reisemedizin,
  },
  {
    path: "/reiseplanung/versicherungen",
    name: "Versicherungen",
    component: Versicherungen,
  },
  {
    path: "/statistiken",
    name: "Statistiken",
    component: AppStatistiken,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: AppImpressum,
  },
  {
    path: "*",
    name: "404",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
export default router;
