<template>
  <div>
    <h1>Legal Disclosure</h1>
    <p>Information provided pursuant to Section 5 of the German Telemedia Act (TMG):</p>
    <p>
      Alexander Zeller
      <br />
      Vogtstr. 4
      <br />
      78250 Tengen
      <br />
    </p>
    <p>
      <strong>Kontakt:</strong>
      <br />
      Telefon: 0163 3914385
      <br />
      E-Mail:
      <a href="mailto:mail@bikingbeyond.de">mail@bikingbeyond.de</a>
      <br />
    </p>

    <p>
      <strong>Legal disclaimer</strong>
      <br />
      <br />
      The contents of these pages were prepared with utmost care. Nonetheless, we cannot assume liability for the
      timeless accuracy and completeness of the information. Our website contains links to external websites. As the
      contents of these third-party websites are beyond our control, we cannot accept liability for them. Responsibility
      for the contents of the linked pages is always held by the provider or operator of the pages.
      <br />
      <br />
      <strong>Data protection</strong>
      <br />
      <br />
      In general, when visiting the website of bikingbeyond.de, no personal data are saved. However, these data can be
      given on a voluntary basis. No data will be passed on to third parties without your consent. We point out that in
      regard to unsecured data transmission in the internet (e.g. via email), security cannot be guaranteed. Such data
      could possibIy be accessed by third parties.
      <br />
      <br />
      <strong>Google Analytics</strong>
      <br />
      <br />
      This website uses Google Analytics, a web analytics service provided by Google, Inc. (»Google«). Google Analytics
      uses cookies, which are text files placed on your computer, to help the website analyze how users use the site.
      The information generated by the cookie about your use of the website (including your IP address) will be
      transmitted to and stored by Google on servers in the United States. In case of activation of the IP
      anonymization, Google will truncate/anonymize the last octet of the IP address for Member States of the European
      Union as well as for other parties to the Agreement on the European Economic Area. Only in exceptional cases, the
      full IP address is sent to and shortened by Google servers in the USA. On behalf of the website provider Google
      will use this information for the purpose of evaluating your use of the website, compiling reports on website
      activity for website operators and providing other services relating to website activity and internet usage to the
      website provider. Google will not associate your IP address with any other data held by Google. By using this
      website, you consent to the processing of data about you by Google in the manner and for the purposes set out
      above.
      <br />
      <br />
      <strong>Google Adsense</strong>
      <br />
      <br />
      This website uses Google Adsense, a web advertising service provided by Google Inc, USA (''Google''). Google
      Adsense uses so-called ''cookies'' (text files), which are stored on your computer and which enable an analysis of
      your use of the website. Google Adsense also uses so-called ''web beacons'' (small invisible graphics) to collect
      information. Through the use of the web beacon, simple actions such as visitor traffic to the website can be
      recorded and collected. The information generated by the cookie and/or web beacon about your use of this website
      (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google
      will use this information for the purpose of evaluating your use of the website in relation to the advertisements,
      compiling reports on website activity and advertisements for website operators and providing other services
      relating to website activity and internet usage. Google may also transfer this information to third parties where
      required to do so by law, or where such third parties process the information on Google's behalf. Google will not
      associate your IP address with any other data held by Google. You can prevent the storage of cookies on your hard
      drive and the display of web beacons by selecting ''do not accept cookies'' in your browser settings (in MS
      Internet Explorer under ''Tools > Internet Options > Privacy > Setting''; in Firefox under ''Tools > Settings >
      Privacy > Cookies''); however, we would like to point out that in this case you may not be able to use all
      functions of this website to their full extent. By using this website, you consent to the processing of data about
      you by Google in the manner and for the purposes set out above.
    </p>
    <br />
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
