<template>
  <v-list>
    <v-list-item-group v-model="gpx_id" active-class="no-active" mandatory>
      <v-list-item
        v-for="item in reversedGpxItems"
        :key="item.id"
        v-bind:id="'gpx_track_id_' + item.id"
        :ripple="false"
        inactive
        @click="zoomToGPX(item.id)"
      >
        <v-list-item-content>
          <v-card elevation="2" outlined shaped tile>
            <v-container fluid>
              <v-row align="start" no-gutters>
                <v-col cols="12">
                  <v-card-title class="justify-center pa-0 text-h5">
                    {{ $t("map.tag") }} {{ item.title }}
                    <v-icon dense class="ml-3" @click="zoomToGPX(item.id)">mdi-map-search</v-icon>
                  </v-card-title>
                  <v-card-subtitle class="text-center pb-0 grey--text pt-4">
                    {{ getDates(item.date, item.date_end) }}
                  </v-card-subtitle>
                  <v-card-subtitle class="text-center pb-4 grey--text pt-0">
                    {{ $t($parent.getTrackDescription(item.description)) }}
                  </v-card-subtitle>
                </v-col>
                <v-col cols="6" align="left" justify="center" class="pl-4">
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">mdi-map-marker-distance</v-icon>
                    {{ item.distance }} km
                  </v-row>
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">mdi-elevation-rise</v-icon>
                    {{ item.elevation_gain }} m
                  </v-row>
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">{{ $parent.getStayTypeIcon(item.stay_type) }}</v-icon>
                    {{ $t($parent.getStayTypeDescription(item.stay_type)) }}
                  </v-row>
                </v-col>

                <v-col cols="6" align="left" justify="center" class="pl-4">
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">mdi-speedometer</v-icon>
                    {{ item.moving_speed }}
                    km/h
                  </v-row>
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">mdi-timer-outline</v-icon>
                    {{ item.moving_time }}
                  </v-row>
                  <v-row no-gutters>
                    <v-icon class="pr-2 pb-1">{{ $parent.getWeatherIcon(item.weather) }}</v-icon>
                    {{ $t($parent.getWeatherDescription(item.weather)) }}
                  </v-row>
                </v-col>

                <v-col cols="12" v-if="item.youtube_id">
                  <div class="ma-2"><youtube fitParent :video-id="item.youtube_id" ref="youtube"></youtube></div>
                </v-col>
                <v-col cols="12" v-if="item.instagram_id" class="my-2">
                  <InstagramPost :post_id="item.instagram_id" />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import InstagramPost from "@/components/map/InstagramPost.vue";
export default {
  props: {
    gpxCollection: { type: Array, default: () => ({}) },
    gpx_id: {
      type: Number,
    },
  },
  components: {
    InstagramPost,
  },
  data() {
    return {};
  },
  mounted() {
    this.map = this.$parent.$refs.Map.mapObject;
  },
  methods: {
    // On function call  zooms to the layer which layerID matches the current gpx_id
    zoomToGPX(gpx_id) {
      this.map = this.$parent.$refs.Map.mapObject;
      let that = this;
      this.map.eachLayer(function (layer) {
        if (layer.layerID == gpx_id) {
          // reset map bounds
          that.map.invalidateSize();
          // try catch in case gpy does´t have bounds
          try {
            that.map.flyToBounds(layer.getBounds());
          } catch {
            let lat = layer._latlng.lat;
            let lng = layer._latlng.lng;
            that.map.flyTo([lat, lng], 13);
          }
        }
      });
    },
    getDates(date, date_end) {
      if (date == date_end) {
        return this.formatDate(date);
      } else {
        return this.formatDate(date) + " - " + this.formatDate(date_end);
      }
    },
    formatDate(input) {
      var datePart = input.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];

      return day + "." + month + "." + year;
    },
  },
  computed: {
    reversedGpxItems() {
      return this.gpxCollection.slice().reverse();
    },
  },
};
</script>

<style>
.no-active::before {
  opacity: 0 !important;
}
.v-list-item:hover::before {
  opacity: 0 !important;
}
.v-list-item--active .v-list-item__content .v-card {
  border: thick solid #0094f6cd;
}
</style>
