<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="0" lg="1" xl="2"></v-col
      ><v-col
        cols="12"
        md="10"
        lg="10"
        xl="8"
        class="main-content rounded-lg pa-2 pa-md-4"
      >
        <WerkzeugDE v-if="locale == 'de'" /> <WerkzeugEN v-if="locale == 'en'" /> </v-col
      ><v-col cols="0" md="0" lg="1" xl="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import WerkzeugDE from "@/components/de/ausruestung/WerkzeugDE.vue";
import WerkzeugEN from "@/components/en/ausruestung/WerkzeugEN.vue";
export default {
  name: "Werkzeug",
  components: {
    WerkzeugDE,
    WerkzeugEN,
  },
  data() {
    return {
      locale: null,
    };
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },

  watch: {
    "$i18n.locale": function (newVal) {
      this.locale = newVal;
    },
  },
};
</script>
