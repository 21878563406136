<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Finanzierung</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">15. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>1. Schätzung der monatlichen Kosten</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Schätzung des Gesamtbudgets</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Möglichkeiten die Kosten zu verringern</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''">
        <v-row no-gutters>
          <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
            1. Schätzung der monatlichen Kosten
          </div>
          <p>
            Um sich über die Finanzierung seiner Reise Gedanken machen zu können sollte man zunächst möglichst akkurat
            festlegen, wieviel Geld man zur Umsetzung seiner Pläne benötigt. Dies hängt natürlich von mehreren Faktoren
            ab. Grundlegend solltest du deine voraussichtlichen Kosten pro Monat bestimmen.
          </p>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/1.png"></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/2.png"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <p>
            Diese setzten sich aus den laufenden Fixkosten (meist aus deinem Heimatland), Kosten für Verpflegung,
            Medizinische Versorgung, Unterkunft und Instandhaltungskosten deines Rades zusammen. Gegebenenfalls kommen
            auch Visa Gebühren auf dich zu.
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/3.png"></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="340" src="@/assets/finanzierung/de/5.png"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/4.png"></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/6.png"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <p>
            Zusätzlich sind Transportmittelkosten und je nach Wunsch Kosten der Freizeitgestaltung zu berücksichtigen.
            Diese können natürlich individuell stark unterschiedlich sein. Allerdings lohnt es sich auch, sich nach
            festlegen einer (groben) Reiseroute vorab zu recherchieren, wie die Lebenshaltungskosten in den Ländern
            durch die du fahren möchtest aussehen.
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/7.png"></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img max-width="350" src="@/assets/finanzierung/de/8.png"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p>
          Eine Vorlage für deine eigene Kostenaufstellung kannst du dir gerne
          <a
            href="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/pdf%2FBudgetplaning_DE_template.pdf?alt=media&token=a17437ee-40ff-4236-86b7-681624e8aea8"
            target="_blank"
            rel="noopener noreferrer"
          >
            hier
          </a>
          herunterladen, eine vorausgefüllte Version
          <a
            href="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/pdf%2FBudgetplaning_DE.pdf?alt=media&token=437196ae-14bd-42aa-85b8-1a80ac3579b3"
            target="_blank"
            rel="noopener noreferrer"
          >
            hier
          </a>
          . Wenn du deine zu erwartenden Kosten pro Monat kennst, kannst du 2 Möglichkeiten zur Planung betrachten:
        </p>

        <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
          2. Schätzung des Gesamtbudgets
        </div>

        <div class="text-h6 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
          Möglichkeit A: Fixes Budget, noch nicht festgelegte Reisedauer
        </div>
        <p>
          Du hast von Vornherein ein festgelegtes Budget zur Verfügung und möchtest die Dauer deiner Reise danach
          richten. Mit der einfachen Rechnung Budget/ Kosten pro Monat solltest du deine Reisedauer ermitteln können.
          Behalte aber unbedingt einen kleinen Puffer zurück, um im Notfall agieren zu können. Wie hoch dieser Puffer
          sein sollte ist pauschal schwer festzulegen, wir würden dir empfehlen, dass der Betrag notfalls ein Flugticket
          vom entferntesten Punkt deiner Route nach Hause decken sollte.
        </p>
        <div class="text-h6 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
          Möglichkeit B: Fixe Reisedauer, noch nicht festgelegtes Budget
        </div>
        <p>
          Du hast eine präzise Reisedauer im Kopf, vielleicht weil du eine bestimmt Route fahren möchtest. Nachdem du
          schätzt, wie lange du dafür etwa brauchst kannst du mit deinen Kosten pro Monat deine Gesamtkosten ermitteln.
          Plane auch hier Puffer ein, sowohl in der Reisedauer (und damit zurückgelegten Kilometern pro Tag) als auch im
          Gesamtbudget.
        </p>
        <div id="3" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
          3. Möglichkeiten die Kosten zu verringern
        </div>
        <p>
          Wenn du schwarz auf weiß siehst, wieviel Geld du zum Umsetzten deiner Reiseträume benötigst kann die Summe
          vielleicht erst einmal erschreckend sein. Es gibt allerdings Möglichkeiten die anfallenden Kosten um ein
          großes Stück zu reduzieren.
          <br />
          Gehe die oben aufgeführten Kategorien Schritt für Schritt durch. Kannst du deine Fixkosten verringern, indem
          du Verträge pausierst oder kündigst? Dies ist vor allem bei Vereinsmitgliedschaften, Fitnessstudios und
          Abonnements möglich und spart gut und gerne 50 bis 100 € pro Monat. Sieh dich nach einer günstigen
          Auslandskranken- und Fahrradversicherung um. Die Krankenversicherung in deinem Heimatland kannst du für die
          Dauer deines Auslandsaufenthaltes kündigen. Eine Übersicht zu einigen Versicherungen haben wir
          <a href="/reiseplanung/versicherungen">hier</a>
          für dich zusammengefasst.
        </p>
        <p>
          Im Bereich der Verpflegung kann es Sinn machen die Art des Brennstoffes für den Kocher zu überdenken. Wir
          haben uns aus Kostengründen für den Betrieb mit Tankstellenbenzin statt Gas entschieden - und sparen damit
          etwa 30 € im Monat. Selbstredend ist die Eigenverpflegung in den meisten Ländern deutlich günstiger als der
          Besuch von Restaurants. Auch wenn bei der Qualität (und Quantität ;) ) von Lebensmitteln, Hygieneartikeln und
          Medizinischer Versorgung sicherlich nicht gespart werden sollte kann es sinnvoll sein, sich bereits bei der
          Kostenaufstellung über die übliche Preisspanne der Produkte zu informieren. Wir alle kennen diese einladenden
          Premiumsupermärkte, in denen das Kaufgefühl zwar stimmt, die Preise allerdings exorbitant hoch ausfallen.
          Preisdifferenzen für ein und dasselbe Produkt sind nicht unüblich – informiert euch daher vorab, in welchen
          Läden Qualität und Preis der Waren stimmen. Gleiches gilt für die Beschaffung von Ersatzteilen und dem
          Austausch von Verschleißteilen am Fahrrad.
        </p>
        <p>
          Wir sehen das größte Potential Kosten zu reduzieren in der Wahl der Unterkunft. Dies ist natürlich eine
          absolut persönliche Entscheidung. Hotels gehen allerdings schnell ins Geld. Günstiger ist es, im Zelt zu
          schlafen. In den meisten Ländern ist Wildzelten jedoch nicht oder nicht immer erlaubt. Es lohnt sich aber
          auch, sich für Campingplätze zu entscheiden. Hier ist die Tourismussaison, die Ausstattung und Größe des
          Campingplatzes ein Preistreiber. Am günstigsten sind (halb-) staatliche oder kommunale Campingplätze, wie
          Beispielsweise Camping Municipal in Frankreich. Das legale Übernachten in freien, öffentlichen Campspots wie
          sie in Nationalparks zu finden sind ist oft sogar gratis. Eine weitere Möglichkeit für die legale gratis
          Übernachtung ist, Anwohner zu fragen, ob ihr auf ihrem Grund zelten dürft. Eine weitere Möglichkeit sind
          Couchsurfing, Warm Showers und WWOOFing, wobei wir nur mit WWOOFing bisher selbst Erfahrungen sammeln konnten.
        </p>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>1. Schätzung der monatlichen Kosten</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Schätzung des Gesamtbudgets</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Möglichkeiten die Kosten zu verringern</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
