<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FVanessa_Waesche.jpeg?alt=media&token=5f4ce4a2-69b5-47d4-b137-3850c1ccdf87"
          max-height="500"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12"><div class="text-h4 text-center py-6">Our clothing</div></v-col>
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"><img src="@/assets/Avatar_Vanessa.jpg" /></v-avatar>
          <p class="text--disabled text-overline text-left">29. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Overview</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title>Our clothing</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="9" class="pr-3">
        <v-row no-gutters>
          <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2">
            Our clothing
          </div>
          <p>
            All of Vanessas clothing is shown in this Youtube video. Make sure to ckeck out the two disclaimers in the
            beginning of the video. Disclaimer zu Beginn des Videos an ;)
          </p>
          <youtube fit-parent video-id="wnknidaMqF4" ref="youtube"></youtube>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Overview</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title>Our clothing</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
