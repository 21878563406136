<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="text-h4 text-center py-6">
          Cost planning and financing
        </div></v-col
      >
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"
            ><img src="@/assets/Avatar_Vanessa.jpg"
          /></v-avatar>
          <p class="text--disabled text-overline text-left">15. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Overview</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title
              >1. Estimate of the monthly costs</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title
              >2. Estimate of the overall budget</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Ways to reduce costs</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="9"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
      >
        <v-row no-gutters>
          <div
            id="1"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            1. Estimate of the monthly costs
          </div>
          <p>
            In order to be able to think about the financing of your trip, you
            should first determine as accurately as possible how much money you
            will need to realize your plans. This, of course, depends on several
            factors.
          </p>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/1.png"
                ></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/2.png"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <p>
            Basically, you should determine your expected costs per month. This
            includes fixed costs (mostly from your home country), costs for
            food, medical care, accommodation and maintenance of your bike and
            camping gear. You may also have to pay visa or tourist fees.
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/3.png"
                ></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="340"
                  src="@/assets/finanzierung/en/5.png"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/4.png"
                ></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/6.png"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <p>
            In addition, transportation costs and, depending on your wishes,
            costs for recreation activities have to be taken into account. Of
            course, these can vary greatly from person to person. However, after
            determining a (rough) itinerary, it is also worth to estimate cost
            of living in the countries you want to visit in advance.
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/7.png"
                ></v-img>
              </v-col>
              <v-col sm="6" class="d-flex justify-center">
                <v-img
                  max-width="350"
                  src="@/assets/finanzierung/en/8.png"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p>
          You can download a template for estimating your personal monthly cost
          <a
            href="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/pdf%2FBudgetplaning_ENG_template.pdf?alt=media&token=fae62621-46e2-47f7-ab71-31ab4de53669"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          , as well as a prefilled version
          <a
            href="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/pdf%2FBudgetplaning_ENG.pdf?alt=media&token=4009142a-7441-40f0-8cd8-cf595806f668"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>

        <div
          id="2"
          class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
        >
          2. Estimate of the overall budget
        </div>
        <p>
          Once you know your expected costs per month, you can consider 2
          options for planning:
        </p>
        <div
          class="text-h6 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
        >
          Option A: Fixed budget, not yet fixed trip duration.
        </div>
        <p>
          You have a fixed budget from the beginning and want to plan the
          duration of your trip accordingly. With the simple calculation
          budget/cost per month you should be able to determine your travel
          duration. Be sure to keep a small buffer to be able to act in case of
          emergency. It is difficult to determine how much this buffer should
          be, but we would recommend that the amount should cover a plane ticket
          from the furthest point of your route back home.
        </p>
        <div
          class="text-h6 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
        >
          Option B: Fixed travel duration, not yet fixed budget
        </div>
        <p>
          You have a precise travel time in mind, maybe because you want to
          travel a certain route. After you estimate how long it will take you,
          you can calculate your total costs with your costs per month. Again,
          allow for buffers in both travel time (and thus kilometers traveled
          per day) and total budget.
        </p>
        <div
          id="3"
          class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
        >
          3. Ways to reduce costs
        </div>
        <p>
          When you see the total number of how much money you need to make your
          travel dreams come true, the sum may seem daunting at first. However,
          there are ways to reduce the costs by a large amount.
          <br />
          Go through the categories above step by step. Can you reduce your
          fixed costs by pausing or canceling contracts? This is especially
          possible with club memberships, gyms and subscriptions, and can easily
          save you 50€ to 100€ per month. Look around for cheap travel health
          and bicycle insurances offering good conditions. You can cancel the
          health insurance in your home country for the duration of your stay
          abroad. We have summarized an overview of some insurances for you
          <a href="/reiseplanung/versicherungen">here</a>
          .
        </p>
        <p>
          When it comes to food, it can make sense to reconsider the type of
          fuel for the stove. For cost reasons we decided to use gasoline
          instead of gas - and save about 30€ per month. Of course,
          self-catering is much cheaper than going to restaurants in most
          countries. Even if quality (and quantity ;) ) of food, hygiene
          articles and medical supply is not a topic you should save money on,
          it can be meaningful to catch up on the usual price range of products.
          We all know those inviting premium supermarkets where the shopping
          experience is great, but the prices are exorbitantly high. Price
          differences for one and the same product are not uncommon - so find
          out in advance in which stores the quality and price of goods are
          right. The same applies to the purchase of spare parts and the
          replacement of wear parts on the bike.
        </p>
        <p>
          We see the greatest potential to reduce costs in the choice of
          accommodation. This is, of course, an absolutely personal decision.
          However, hotels can cost a pretty penny. It is cheaper to sleep in a
          tent. In most countries, however, wild camping is not or not always
          allowed. However, it is also worthwhile to opt for campsites. The
          tourist season, equipment and size of the campsite is a price driver.
          The cheapest are (semi-) state or municipal campsites, such as Camping
          Municipal in France. Legal overnight camping in open, public campsites
          such as those found in national parks is often even free. Another
          option for legal free overnight camping is to ask local residents if
          you can camp on their property. Another possibility is Couchsurfing,
          Warm Showers and WWOOFing, although we have only had personal
          experience with WWOOFing so far.
        </p>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="0"
        md="3"
        class="d-flex flex-column align-end justify-start"
      >
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Overview</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title
                >1. Estimate of the monthly costs</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title
                >2. Estimate of the overall budget</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Ways to reduce costs</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}
</style>
