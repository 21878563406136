<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="0" md="0" lg="1" xl="2"></v-col>
      <v-col cols="12" md="12" lg="10" xl="8" class="main-content rounded-lg pa-2 pa-md-4">
        <v-row no-gutters>
          <v-col cols="12">
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/biking-beyond.appspot.com/o/img%2FAgentur_Arbeit.jpg?alt=media&token=c26dd0ec-ff15-4f4f-9179-748600c99a8c"
              max-height="500"
            ></v-img>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12"><div class="text-h4 text-center py-6">Arbeitslos melden?</div></v-col>
        </v-row>
        <v-row no-gutters class="pa-2 pb-4">
          <v-col cols="12">
            <div class="d-flex justify-start">
              <v-avatar class="mr-4"><img src="@/assets/Avatar_Alexander.jpg" /></v-avatar>
              <p class="text--disabled text-overline text-left">15. Mai 2022</p>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-card class="mb-8 mx-2" color="#495867" width="100%">
            <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
            <v-list>
              <v-list-item @click="scrollTo(1)">
                <v-list-item-title>1. Muss ich mich überhaupt arbeitslos melden?</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollTo(2)">
                <v-list-item-title>2. Habe ich Anspruch auf Arbeitslosengeld (ALG1)?</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollTo(3)">
                <v-list-item-title>3. Arbeitssuchend vs. arbeitslos?</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollTo(4)">
                <v-list-item-title>4. Was ist eine Sperrzeit?</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollTo(5)">
                <v-list-item-title>5. Wie ist das mit der Versicherung?</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollTo(6)">
                <v-list-item-title>6. Warum sollte ich mich vor meiner Reise darum kümmern?</v-list-item-title>
              </v-list-item>
              <v-list-item @click="scrollTo(6)">
                <v-list-item-title>7. Schritt für Schritt</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="9" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''">
            <v-row no-gutters>
              <v-col>
                <p>
                  Wer einen längeren Auslandsaufenthalt geplant hat, muss viel organisieren: Versicherungen, Flüge,
                  Ausrüstung, ... Je nach Lebenssituation gehört auch die Agentur für Arbeit zu den Dingen mit denen man
                  sich beschäftigen muss. Wir selbst hatten vor unserer Fahrradreise noch keine Berührungspunkte mit
                  diesem Thema und waren dementsprechend etwas ratlos was getan werden muss. Hier möchten wir dir unsere
                  Erfahrungen mitteilen. Wenn du also Fragen hast wie:
                </p>
                <ul class="font-size-small">
                  <li>Muss ich mich überhaupt Arbeitslos melden?</li>
                  <li>Habe ich Anspruch auf Arbeitslosengeld?</li>
                  <li>Sollte ich mich vor meiner Reise darum kümmern?</li>
                </ul>
                <p class="pt-3">dann findest du hier die Antworten.</p>
                <v-alert outlined type="warning" elevation="1">
                  Wir haben diese Informationen mit bestem Wissen und Gewissen erstellt. Bitte informiere dich aber auch
                  immer selbst. Wenn du Fehler entdeckst kannst du uns diese gerne mit einem Nachweis oder einer Quelle
                  <a href="mailto:mail@bikingbeyond.de">hier</a>
                  an uns melden.
                </v-alert>

                <div id="1" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  1. Muss ich mich überhaupt arbeitslos melden?
                </div>
                <p>
                  Laut
                  <a
                    href="https://www.arbeitsagentur.de/datei/dok_ba015582.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    § 38 des Sozialgesetzbuch (SBG) Drittes Buch (III)
                  </a>
                  ist man verpflichtet sich spätestens 3 Monate vor Beendigung des Arbeitsverhältnisses, oder 3 Tage
                  nach Kenntnisserlangung über das Ende des Arbeitsverhältnisses arbeitssuchend zu melden. Aus der
                  Erfahrung könne wir dir allerdings sagen, dass diese Pflicht nur wirklich relevant ist wenn du auch
                  tatsächlich Anspruch auf Leistungen hast, oder die Hilfe der Agentur für Arbeit anderweitig in
                  Anspruch nehmen möchtest. Als wir beispielsweise nach unserem Studium für mehrere Monate im Ausland
                  waren, haben wir uns nicht arbeitslos gemeldet, da wir ohnhin keinen Aspruch auf Arbeitslosengeld
                  gehabt hätten. Doch was sind denn nun die Bedingungen für einen Anspruch?
                </p>
                <div id="2" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  2. Habe ich Anspruch auf Arbeitslosengeld (ALG1)?
                </div>
                <p>
                  Anspruch auf Arbeitslosengeld (ALG1) hat man, wenn man die
                  <a
                    href="https://www.arbeitsagentur.de/finanzielle-hilfen/arbeitslosengeld-anspruch-hoehe-dauer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anwartschaftszeit
                  </a>
                  erfüllt. Das bedeutet, dass man in den
                  <b>30 Monaten vor Arbeitslosmeldung mindestens 12 Monate versicherungspflichtig beschäftigt</b>
                  war. Die Dauer für die man ALG1 erhält beträgt für unter 50-jährige maximal 12 Monate. Die Höhe der
                  Bezüge hängt von deinem Bruttogehalt der letzten 12 Monate ab und lässt sich mit diesem offiziellen
                  <a href="https://www.pub.arbeitsagentur.de/start.html" target="_blank" rel="noopener noreferrer">
                    Rechner der Bundesagentur für Arbeit
                  </a>
                  bestimmen.
                </p>
                <div id="3" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  3. Arbeitssuchend vs. arbeitslos?
                </div>
                <p>
                  Sich arbeitssuchend und arbeitslos zu melden sind unterschiedliche Vorgänge. Sobald das Ende deiner
                  Beschäftigung in Sicht ist, kannst du dich arbeitssuchend melden. Die Agentur für Arbeit unterstützt
                  dich dann bei deiner Jobsuche, so dass es womöglich gar nicht erst zu einer Arbeitslosigkeit kommt.
                  Arbeitssuchend ist man also, während man noch eine Beschäftigung hat.
                  <br />
                  Arbeitslos meldet man sich spätestens am ersten Tag der Arbeitslosigkeit. Die Arbeitslosmeldung ist
                  eine Voraussetzung für den Anspruch auf Arbeitslosengeld.
                  <br />
                  <b>
                    Kurz gesagt meldest du dich also zuerst arbeitssuchend und spätestens am ersten Tag ohne Job
                    arbeitslos.
                  </b>
                </p>
                <div id="4" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  4. Was ist eine Sperrzeit?
                </div>
                <p>
                  Wenn du deine Beschäftigung selbst gekündigt hast bekommst du eine Sperrfrist von 3 Monaten auferlegt.
                  Das heißt, dass du statt der maximalen 12 Monate nur noch 9 Monate Ansruch hast. Warst du z.B.
                  befristet angestellt und dein Vertrag ist einfach ausgelaufen hast du Anspruch auf die vollen 12
                  Monate.
                </p>
                <div id="5" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  5. Wie ist das mit der Versicherung?
                </div>
                <p>
                  Wenn du nach deiner Reise nach Deutschland zurückkommst ist es beruhigend zu wissen, dass du für den
                  Anfang finanzielle Unterstützung bekommst. Doch die Meldung als arbeitslos hat weitere Vorteile. Zum
                  Einen wird der Beitrag zur Krankenkasse von der Agentur für Arbeit übernommen. Die Alternative wäre
                  sich freiwillig zu versichern, was rund 200€ im Monat kostet und damit nicht ganz billig ist.
                  <br />
                  Außerdem werden für Monate, die du arbeitslos gemeldet bist die Sozialversicherungsbeiträge und damit
                  auch die Rentenversicherung von der Agentur für Arbeit übernommen. Das ist vor allem wichtig für deine
                  Beitragsjahre in der Rentenversicherung.
                </p>
                <div id="6" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  6. Warum sollte ich mich vor meiner Reise darum kümmern?
                </div>
                <p>
                  Es gibt mehrere Gründe, warum du dich vor dem Beginn deiner Reise um deine Meldung zur
                  Arbeitslosigkeit kümmern solltest.
                  <br />
                  Der wohl wichtigste ist, dass deinen Anspruch erhalten bleibt. Wenn du z.B. 2 Jahre unterwegs bist und
                  dich bei deiner Rückkehr arbeitslos meldest, erfüllst du die Bedingungen zur Anwartschaft nicht mehr,
                  da du in den letzten 30 Monaten keine 12 Monate versicherungspflichtig beschäftigt warst. Hast du dir
                  aber deinen Anspruch auf ALG1 vor deiner Reise gesichert bleibt dieser für bis zu 4 Jahre erhalten.
                  <br />
                  Ein weiterer Grund ist, dass deine eventuelle Sperrzeit auch in der Zeit abläuft in der du auf Reisen
                  bist. Hast du beispielsweise wie wir eine 3-monatige Sperrzeit und würdest für 1 Jahr unterwegs sein,
                  wäre diese bei deiner Rückkehr bereits abgelaufen und du hättest direkt einen Anspruch auf Leistungen.
                </p>
                <div id="7" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  7. Schritt für Schritt
                </div>
                <p>
                  Im Anschluss findest du noch eine kleine chronologische Auflistung wie sich die Arbeitslosenmeldung
                  bei uns gestaltet hat.
                </p>
                <v-alert outlined type="info" color="#495867" elevation="1">
                  Wir empfehlen dir, die
                  <b>Onlinefunktion deines Personalausweises</b>
                  zu aktivieren. Damit können alle Vorgänge auch bequem von Zuhause aus erledigt werden.
                </v-alert>
                <div class="text-subtitle-1 font-weight-bold text-center pt-4">3 Monate vor Beschäftigungsende</div>
                <v-card color="#495867" class="my-4">
                  <v-card-title class="white--text text-h6">Arbeitssuchend melden</v-card-title>
                  <v-card-text class="white text--primary">
                    <p class="timeline">
                      Der erste Schritt ist, sich arbeitssuchend zu melden. Das geht vor Ort, telefonisch, schriftlich
                      oder am einfachsten online. Dazu meldest du dich an dem
                      <a
                        href="https://web.arbeitsagentur.de/websso-prod/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Onlineportal
                      </a>
                      der Agentur für Arbeit an. Solltest du dich noch nicht registriert haben, bekommst du erst Post
                      mit den Zugangsdaten was ein paar Tage in Anspruch nimmt.
                    </p>
                  </v-card-text>
                </v-card>
                <v-row no-gutters>
                  <v-col class="text-center"><v-icon x-large>mdi-arrow-down</v-icon></v-col>
                </v-row>
                <div class="text-subtitle-1 font-weight-bold text-center">Nach der Meldung als arbeitssuchend</div>
                <v-card color="#495867" class="my-4">
                  <v-card-title class="white--text text-h6">Termin mit Sachbearbeiter/in</v-card-title>
                  <v-card-text class="white text--primary">
                    <p class="timeline">
                      Nachdem du dich arbeitssuchend gemeldet hast wirst du irgendwann aufgefordert Angaben zu deiner
                      Person und deinem beruflichen Hintergrund zu machen. Solltest du dich online arbeitssuchend
                      gemeldet haben kannst du diese Information direkt in dem Portal eintragen. Außerdem hast du die
                      Möglichkeit einen Beratungstermin zu vereinbaren. Wir konnten dieses Gespräch telefonsich
                      abhalten. Hier kannst du deinem Sachbearbeiter/in deine Situation erläutern. Das würden wir auch
                      absolut empfehlen, sonst wirst du eventuell mit Vermittlungsangeboten, die du gar nicht haben
                      möchtest, zugehäuft.
                    </p>
                  </v-card-text>
                </v-card>
                <v-row no-gutters>
                  <v-col class="text-center"><v-icon x-large>mdi-arrow-down</v-icon></v-col>
                </v-row>
                <div class="text-subtitle-1 font-weight-bold text-center">
                  Spätestens am ersten Tag der Arbeitslosigkeit
                </div>
                <v-card color="#495867" class="my-4">
                  <v-card-title class="white--text text-h6">Arbeitslos melden</v-card-title>
                  <v-card-text class="white text--primary">
                    <p class="timeline">
                      Spätestens am ersten Tag deiner Arbeitslosigkeit musst du dich offiziell arbeitslos melden. Auch
                      das geht inzwischen online. Dazu musst du dich aber mit der Online-Funktion deines
                      Personalausweises ausgewiesen haben. Jetzt werden deine Anprüche auf ALG1 ermittelt. Dazu brauchst
                      du noch ein paar Unterlagen, wie z.B. eine Arbeitsbescheinigung oder Gehaltsnachweise. Sofern du
                      eine Sperrzeit hast, beginnt diese abzulaufen.
                    </p>
                  </v-card-text>
                </v-card>
                <v-row no-gutters>
                  <v-col class="text-center"><v-icon x-large>mdi-arrow-down</v-icon></v-col>
                </v-row>
                <div class="text-subtitle-1 font-weight-bold text-center">Vor dem Antritt deiner Reise</div>
                <v-card color="#495867" class="my-4">
                  <v-card-title class="white--text text-h6">Bei der Agentur für Arbeit abmelden</v-card-title>
                  <v-card-text class="white text--primary">
                    <p class="timeline">
                      Bevor du deine Reise antrittst, musst du dich bei der Agentur für Arbeit abmelden. Du sagst also,
                      dass du dem Arbeitsmarkt nicht mehr zur Verfügung stehst. Damit bekommst du erstmal keine Bezüge
                      mehr (und bist auch nicht mehr krankenversichert), dein verbleibender Anpruch auf ALG1 bleibt aber
                      bis zu 4 Jahre erhalten.
                    </p>
                  </v-card-text>
                </v-card>
                <div id="7" class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-12 mb-2">
                  Noch Fragen?
                </div>
                <p>
                  Wie Anfangs erwähnt sind wir keine Experten zu diesem Thema. Ich hoffe wir konnten euch trotzdem einen
                  Überblick verschaffen. Falls Ihr noch ungeklärte Fragen habt ist natürlich die Agentur für Arbeit der
                  beste Ansprechpartner. Wir haben dort auch bisher nur gute Erfahrungen gehabt. Ansonsten empfanden wir
                  die offizielle
                  <a
                    href="https://www.arbeitsagentur.de/finanzielle-hilfen/arbeitslosengeld-anspruch-hoehe-dauer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website der Agentur für Arbeit
                  </a>
                  und auch das
                  <a
                    href="https://www.arbeitsagentur.de/datei/merkblatt-fuer-arbeitslose_ba015368.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Merkblatt für Arbeitslose
                  </a>
                  als sehr hilfreich.
                </p>
                <!-- <hr class="blue-grey--text text--darken-1" /> -->
                <!-- <v-row>
                  <v-col cols="12" md="6">
                    <v-card to="ausruestung/camping" class="mx-auto">
                      <v-img class="white--text align-end" src="@/assets/img/home/ausruestung_card.jpg"></v-img>
                      <v-card-title style="word-break: break-word">Hier geht es zu unserer Ausrüstung</v-card-title>
                      <v-card-text class="text--primary py-0" style="height: 44px">
                        <div>Eine Auflistung unserer Ausrüstungsgegenstände</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card to="ausruestung/camping" class="mx-auto">
                      <v-img class="white--text align-end" src="@/assets/img/home/ausruestung_card.jpg"></v-img>
                      <v-card-title style="word-break: break-word">Hier geht es zu unserer Ausrüstung</v-card-title>
                      <v-card-text class="text--primary py-0" style="height: 44px">
                        <div>Eine Auflistung unserer Ausrüstungsgegenstände</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="0" md="3" class="d-flex flex-column align-end justify-start">
            <v-card style="position: sticky; top: 60px" color="#495867" width="260">
              <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
              <v-list class="py-1" dense two-line>
                <v-list-item @click="scrollTo(1)">
                  <v-list-item-title>1. Muss ich mich überhaupt arbeitslos melden?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo(2)">
                  <v-list-item-title>2. Habe ich Anspruch auf Arbeitslosengeld (ALG1)?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo(3)">
                  <v-list-item-title>3. Arbeitssuchend vs. arbeitslos?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo(4)">
                  <v-list-item-title>4. Was ist eine Sperrzeit?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo(5)">
                  <v-list-item-title>5. Wie ist das mit der Versicherung?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo(6)">
                  <v-list-item-title>6. Warum sollte ich mich vor meiner Reise darum kümmern?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo(6)">
                  <v-list-item-title>7. Schritt für Schritt</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" md="0" lg="1" xl="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 4px 8px !important;
}
.v-card__text {
  padding: 10px 10px 2px 10px !important;
}

.v-list-item__title {
  font-size: 0.95rem !important;
  line-height: 1.2rem !important;
}

.scroll-header {
  scroll-margin: 64px;
}

.timeline {
  line-height: 1.8;
  font-size: 0.925rem;
}
</style>
