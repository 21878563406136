<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="0" md="0" lg="1" xl="2"></v-col>
      <v-col cols="12" md="12" lg="10" xl="8" class="main-content rounded-lg pa-2 pa-md-4">
        <HomeDE v-if="locale == 'de'" />
        <HomeEN v-if="locale == 'en'" />
      </v-col>
      <v-col cols="0" md="0" lg="1" xl="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeDE from "@/components/de/HomeDE.vue";
import HomeEN from "@/components/en/HomeEN.vue";
export default {
  name: "AppHome",
  components: {
    HomeDE,
    HomeEN,
  },
  data: () => ({
    locale: null,
  }),
  mounted() {
    this.locale = this.$i18n.locale;
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.locale = newVal;
    },
  },
};
</script>
