<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="text-h4 text-center py-6">
          Traffic Rules for cyclists in Europe
        </div></v-col
      >
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"
            ><img src="@/assets/Avatar_Vanessa.jpg"
          /></v-avatar>
          <p class="text--disabled text-overline text-left">29. May 2022</p>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Overview</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title
              >1. Traffic rules for cyclists in Europe</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Tabular overview</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Interesting facts</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="9"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
      >
        <v-row no-gutters>
          <div
            id="1"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            1. Traffic rules for cyclists in Europe
          </div>
          <p>
            The traffic rules for cyclists are by no means the same in every
            country in the world. It is therefore worth taking a look at the
            traffic rules before crossing a national border. Of course, it
            always makes sense to ride with a helmet - regardless of whether
            this is a regulation or merely a recommendation. However, there are
            far more rules or even rights that cyclists worldwide should be
            aware of. We have therefore summarized for you an overview of the
            most important rules and rights of cyclists in Europe.
          </p>
          <p>
            We have therefore summarized for you an overview of the most
            important rules and rights of cyclists in Europe.
          </p>
          <v-alert outlined type="warning" elevation="1">
            We have compiled this information to the best of our knowledge and
            belief. However, please also inform yourself. In case you discover
            any mistakes, please feel free to inform us by e-mail to
            <a href="mailto:mail@bikingbeyond.de">mail@bikingbeyond.de</a>
            with proof or a reference.
          </v-alert>
          <div
            id="2"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            2. Tabular overview
          </div>
          <p>
            All bicycles must be equipped with a functioning
            <b>
              brake system, a bell (exception: Switzerland), a red reflector at
              the rear and a red rear light. In front, white or yellow lighting
            </b>
            is mandatory, depending on the country.
          </p>
          <p>
            <b>
              Talking on the phone without a hands-free system and operating the
              smartphone while driving is prohibited almost everywhere!
            </b>
          </p>
          <v-card class="my-4">
            <v-card-title>
              Traffic rules by country
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="search a country"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-select
              v-model="value"
              :items="shownValues"
              label="select categories"
              multiple
              return-object
              hint="select categories to be displayed"
              persistent-hint
              class="select ma-4"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 1" class=""
                  >({{ value.length }} selected)</span
                >
              </template>
            </v-select>

            <v-data-table
              class="mytable"
              :headers="selectedHeaders"
              :items="table"
              :search="search"
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.phone`]="{ item }">
                <v-icon v-if="item.phone == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.phone == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div v-if="item.phone !== 'nein' && item.phone !== 'ja'">
                  {{ item.phone }}
                </div>
              </template>
              <template v-slot:[`item.helmet`]="{ item }">
                <v-icon v-if="item.helmet == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.helmet == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div v-if="item.helmet !== 'nein' && item.helmet !== 'ja'">
                  {{ item.helmet }}
                </div>
              </template>
              <template v-slot:[`item.reflectors`]="{ item }">
                <v-icon v-if="item.reflectors == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.reflectors == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="item.reflectors !== 'nein' && item.reflectors !== 'ja'"
                >
                  {{ item.reflectors }}
                </div>
              </template>
              <template v-slot:[`item.side_by_side`]="{ item }">
                <v-icon v-if="item.side_by_side == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.side_by_side == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="
                    item.side_by_side !== 'nein' && item.side_by_side !== 'ja'
                  "
                >
                  {{ item.side_by_side }}
                </div>
              </template>
              <template v-slot:[`item.turn_right_red`]="{ item }">
                <v-icon
                  v-if="item.turn_right_red == 'ja'"
                  color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon
                  v-if="item.turn_right_red == 'nein'"
                  color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="
                    item.turn_right_red !== 'nein' &&
                    item.turn_right_red !== 'ja'
                  "
                >
                  {{ item.turn_right_red }}
                </div>
              </template>
              <template v-slot:[`item.bike_paths`]="{ item }">
                <v-icon v-if="item.bike_paths == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.bike_paths == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="item.bike_paths !== 'nein' && item.bike_paths !== 'ja'"
                >
                  {{ item.bike_paths }}
                </div>
              </template>
              <template v-slot:[`item.legal`]="{ item }">
                <v-icon v-if="item.legal == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.legal == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div v-if="item.legal !== 'nein' && item.legal !== 'ja'">
                  {{ item.legal }}
                </div>
              </template>
              <template v-slot:[`item.earphones`]="{ item }">
                <v-icon v-if="item.earphones == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.earphones == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="item.earphones !== 'nein' && item.earphones !== 'ja'"
                >
                  {{ item.earphones }}
                </div>
              </template>
            </v-data-table>
          </v-card>

          <p>
            The stated <b>per mille limits</b> apply to adult drivers over the
            age of 18 or 21 (depending on the country) outside the probationary
            period.
          </p>
          <p>
            Some countries, such as France, Italy or Croatia, also require
            cyclists to wear <b>reflectors on their person</b>, such as a
            high-visibility vests, when visibility is poor. This refers, among
            other things, to riding through tunnels, at night or in poor
            visibility due to weather conditions.
          </p>
          <p>
            When <b>riding side by side</b>, the permit is usually valid if
            other traffic is not prevented from overtaking and regular road
            traffic can remain in natural flow.
          </p>
          <p>
            It is also important to note that in countries where it is
            <b>compulsory to use cycle paths</b>, this also applies explicitly
            if they are in poor condition.
          </p>
          <p>
            There are some countries where, in the event of an accident with a
            car/truck, the cyclist is <b>not automatically in the right</b>. In
            Europe, these include Great Britain, Ireland, Malta, Romania and
            Cyprus.
          </p>
          <div
            id="3"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            3. Interesting facts
          </div>
          <p>
            There are also traffic rules for cyclists in some places that have
            not made it into our table. For example, in Denmark it is forbidden
            to turn left directly. If cyclists want to turn left at an
            intersection, they must first cross the intersection, then turn
            around and turn right.<br />
            In Great Britain there is no direct right-of-way regulation, the
            right-of-way is signposted at every intersection. In addition, it is
            explicitly permitted to overtake a stationary line of cars. The same
            applies to Switzerland, while in Spain it is forbidden to pass a
            stationary line of cars. <br />
            Lithuania is the only European country where cyclists are also
            affected by the daytime running light obligation.<br />
            Poland protects its cyclists by allowing them to ride on the
            pavement from a speed limit of 50 km/h, provided it is at least 2
            metres wide. This is similar in Belgium. There, cyclists are also
            allowed to use the grass verge or pavement outside the city limits
            if there is no cycle path. In Norway, cyclists are allowed to use
            the pavement if they ride at walking speed. <br />The most bizarre
            ban for us: in the Czech Republic it is not allowed to smoke while
            cycling. That wouldn't have occurred to us anyway ;)
          </p>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="0"
        md="3"
        class="d-flex flex-column align-end justify-start"
      >
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Overview</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title
                >1. Traffic rules for cyclists in Europe</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Tabular overview</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Interesting facts</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      value: [],
      shownValues: [],
      selectedHeaders: [],
      headers: [
        {
          text: "Country",
          align: "start",
          sortable: false,
          value: "country",
        },
        { text: "Left- or right-hand driving", value: "driving_site" },
        { text: "Phone allowed while driving", value: "phone" },
        { text: "Helmet required", value: "helmet" },
        {
          text: "Reflectors on person required when bad vision",
          value: "reflectors",
        },
        { text: "Side-by-side biking allowed", value: "side_by_side" },
        {
          text: "Turning right on red signal allowed",
          value: "turn_right_red",
        },
        { text: "Required to use bike paths", value: "bike_paths" },
        { text: "Alcohol limit ‰", value: "alcohol_limit" },
        { text: "Blame in accident with car", value: "legal" },
        { text: "Earphones allowed", value: "earphones" },
      ],
      table: [
        {
          country: "Albania",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.1",
          legal: "",
          earphones: "",
        },
        {
          country: "Andorra",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Belgium",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "ja",
          turn_right_red: "ja",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Bosnia and Herzegovina",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.3",
          legal: "",
          earphones: "",
        },
        {
          country: "Bulgaria",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Denmark",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "only when no traffic",
          turn_right_red: "ja",
          alcohol_limit: "personal resilience",
          legal: "",
          earphones: "",
        },
        {
          country: "Germany",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "with traffic signs",
          bike_paths: "",
          alcohol_limit: "1.60",
          legal: "",
          earphones: "",
        },
        {
          country: "Estonia",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.19",
          legal: "",
          earphones: "",
        },
        {
          country: "Finland",
          driving_site: "R",
          helmet: "ja",
          reflectors: "",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "personal resilience",
          legal: "",
          earphones: "",
        },
        {
          country: "France",
          driving_site: "R",
          helmet: "",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "ja",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Georgia",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.2",
          legal: "",
          earphones: "",
        },
        {
          country: "Greece",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "nein",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Great Britain",
          driving_site: "L",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "ja",
          bike_paths: "nein",
          alcohol_limit: "personal resilience",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Ireland",
          driving_site: "L",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "personal resilience",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Iceland",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.2",
          legal: "",
          earphones: "",
        },
        {
          country: "Italy",
          driving_site: "R",
          helmet: "",
          reflectors: "ja",
          phone: "",
          side_by_side: "nein",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Kosovo",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Croatia",
          driving_site: "R",
          helmet: "U16",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Latvia",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Liechtenstein",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.8",
          legal: "",
          earphones: "",
        },
        {
          country: "Lithuania",
          driving_site: "R",
          helmet: "U18",
          reflectors: "ja",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.4",
          legal: "",
          earphones: "",
        },
        {
          country: "Luxembourg",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Macedonia",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Malta",
          driving_site: "L",
          helmet: "ja",
          reflectors: "ja",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.8",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Moldova",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Monaco",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Montenegro",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Netherlands",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "ja",
          turn_right_red: "ja",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Norway",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "personal resilience",
          legal: "",
          earphones: "",
        },
        {
          country: "Austria",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "nein",
          side_by_side: "nein",
          bike_paths: "ja",
          alcohol_limit: "0.8",
          legal: "",
          earphones: "",
        },
        {
          country: "Poland",
          driving_site: "R",
          helmet: "U12",
          reflectors: "",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "0.2",
          legal: "",
          earphones: "",
        },
        {
          country: "Portugal",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "only on bike paths",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Romania",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Russia",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.356",
          legal: "",
          earphones: "",
        },
        {
          country: "San Marino",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Sweden",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "personal resilience",
          legal: "",
          earphones: "",
        },
        {
          country: "Switzerland",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "nein",
          turn_right_red: "with traffic signs",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Serbia",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          alcohol_limit: "0.3",
          legal: "",
          earphones: "",
        },
        {
          country: "Slowakia",
          driving_site: "R",
          helmet: "ages 18+, Outside towns",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          alcohol_limit: "0.0",
          legal: "",
          earphones: "",
        },
        {
          country: "Slowenia",
          driving_site: "R",
          helmet: "U18",
          reflectors: "ja",
          turn_right_red: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Spain",
          driving_site: "R",
          helmet: "Outside towns",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "nein",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Czech republic",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          bike_paths: "Ja",
          alcohol_limit: "0.0",
          legal: "",
          earphones: "",
        },
        {
          country: "Turkey",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Ukraine",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0",
          legal: "",
          earphones: "",
        },
        {
          country: "Hungary",
          driving_site: "R",
          helmet: "",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0",
          legal: "",
          earphones: "",
        },
        {
          country: "Vatican",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Belarus",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Cyprus",
          driving_site: "L",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "nein",
          earphones: "",
        },
      ],
    };
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  watch: {
    value(val) {
      let country = {
        text: "Country",
        align: "start",
        sortable: false,
        value: "country",
      };
      let arr = JSON.parse(JSON.stringify(val));
      arr.unshift(country);
      console.log(arr);
      this.selectedHeaders = arr;
    },
  },
  created() {
    this.selectedHeaders = this.headers;
    let arr = JSON.parse(JSON.stringify(this.headers));
    const indexOfObject = arr.findIndex((object) => {
      return object.value === "country";
    });

    arr.splice(indexOfObject, 1);
    this.shownValues = arr;
    this.value = arr;
  },
};
</script>

<style scoped>
.select {
  width: 200px;
}
.v-data-table-header {
  background-color: #eccbb6 !important;
}
</style>
