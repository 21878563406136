<template>
  <v-container fluid>
    <v-row no-gutters
      ><v-col cols="0" md="0" lg="1" xl="2"></v-col
      ><v-col
        cols="12"
        md="10"
        lg="10"
        xl="8"
        class="main-content rounded-lg pa-2 pa-md-4"
      >
        <AllgemeinDE v-if="locale == 'de'" />
        <AllgemeinEN v-if="locale == 'en'" /> </v-col
      ><v-col cols="0" md="0" lg="1" xl="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import AllgemeinDE from "@/components/de/reiseplanung/AllgemeinDE.vue";
import AllgemeinEN from "@/components/en/reiseplanung/AllgemeinEN.vue";
export default {
  name: "Allgemein",
  components: {
    AllgemeinDE,
    AllgemeinEN,
  },
  data() {
    return {
      locale: null,
    };
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },

  watch: {
    "$i18n.locale": function (newVal) {
      this.locale = newVal;
    },
  },
};
</script>
