<template>
  <v-navigation-drawer
    width="70%"
    height="100%"
    v-model="child_drawer"
    @input="$emit('update:drawer', child_drawer)"
    app
  >
    <v-list nav dense>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        Home
      </v-list-item>
      <v-list-item to="/karte">
        <v-list-item-icon>
          <v-icon>mdi-map</v-icon>
        </v-list-item-icon>
        {{ $t("menu.karte") }}
      </v-list-item>
      <v-list-item to="/fahrrad">
        <v-list-item-icon>
          <v-icon>mdi-bike</v-icon>
        </v-list-item-icon>
        {{ $t("menu.fahrrad") }}
      </v-list-item>
      <v-list-group no-action prepend-icon="mdi-bag-personal">
        <template v-slot:activator>
          <v-list-item-content>
            {{ $t("menu.ausruestung") }}
          </v-list-item-content>
        </template>
        <v-list-item to="/ausruestung/camping">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.camping") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/technik">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.technik") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/taschen">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.taschen") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/werkzeug">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.werkzeug") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/ausruestung/kleidung">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.kleidung") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group no-action prepend-icon="mdi-note-edit">
        <template v-slot:activator>
          <v-list-item-content>
            {{ $t("menu.reiseplanung") }}
          </v-list-item-content>
        </template>
        <v-list-item to="/reiseplanung/allgemeines">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.allgemein") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/reiseplanung/verkehrsregeln">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.verkehrsregeln") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/reiseplanung/reisemedizin">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.medizin") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/reiseplanung/verischerungen">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.versicherungen") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/reiseplanung/arbeitslosigkeit">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.arbeitslosigkeit") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/reiseplanung/finanzierung">
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.finanzierung") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/statistiken">
        <v-list-item-icon>
          <v-icon>mdi-chart-pie</v-icon>
        </v-list-item-icon>
        {{ $t("menu.statistiken") }}
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      child_drawer: this.drawer,
    };
  },
  watch: {
    drawer: function () {
      this.child_drawer = this.drawer;
    },
  },
};
</script>

<style></style>
