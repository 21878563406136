<template>
  <v-container>
    <v-row justify="center" class="pa-2">
      <div class="text-h4 text-center">No Translation available</div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
