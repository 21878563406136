<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="text-h4 text-center py-6">
          Verkehrsregeln für Radfahrer in Europa
        </div></v-col
      >
    </v-row>
    <v-row no-gutters class="pa-2 pb-0">
      <v-col cols="12">
        <div class="d-flex justify-start">
          <v-avatar class="mr-4"
            ><img src="@/assets/Avatar_Vanessa.jpg"
          /></v-avatar>
          <p class="text--disabled text-overline text-left">29. Mai 2022</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card class="mb-8 mx-2" color="#495867" width="100%">
        <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
        <v-list>
          <v-list-item @click="scrollTo(1)">
            <v-list-item-title
              >1. Verkehrsregeln für Radfahrer in Europa</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="scrollTo(2)">
            <v-list-item-title>2. Tabellarische Übersicht</v-list-item-title>
          </v-list-item>
          <v-list-item @click="scrollTo(3)">
            <v-list-item-title>3. Wissenswertes</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="9"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : ''"
      >
        <v-row no-gutters>
          <div
            id="1"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            1. Verkehrsregeln für Radfahrer in Europa
          </div>
          <p>
            Die Verkehrsregeln für Radfahrer sind längst nicht in jedem Land der
            Welt gleich. Es lohnt sich also, vor dem Überschreiten einer
            Landesgrenze einen Blick auf die Verkehrsregeln zu werfen. Natürlich
            ist es immer sinnvoll mit Helm zu fahren – egal ob dies nun
            Vorschrift ist, oder lediglich eine Empfehlung. Doch gibt es darüber
            hinaus noch weit mehr Regeln oder sogar Rechte, die Radfahrer
            weltweit laut Straßenverkehrsordnung berücksichtigen sollten.
          </p>
          <p>
            Wir haben daher für euch eine Übersicht der wichtigsten Regeln und
            Rechte von Radfahrern in Europa zusammengefasst.
          </p>
          <v-alert outlined type="warning" elevation="1">
            Wir haben diese Informationen nach bestem Wissen und Gewissen
            erstellt. Bitte informiere dich aber auch immer selbst. Wenn du
            Fehler entdeckst kannst du uns diese gerne mit einem Nachweis oder
            einer Quelle per Mail an
            <a href="mailto:mail@bikingbeyond.de">mail@bikingbeyond.de</a>
            an uns melden.
          </v-alert>
          <div
            id="2"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            2. Tabellarische Übersicht
          </div>
          <p>
            Allgemein gilt in Europa die Pflicht, dass alle Fahrräder mit einer
            funktionierenden
            <b>
              Bremsanlage, einer Klingel (Ausnahme: Schweiz), einem roten
              Reflektor hinten sowie einem roten Rücklicht
            </b>
            ausgestattet sein müssen. Vorne ist je nach Land weiße oder gelbe
            Beleuchtung vorgeschrieben.
            <br />
            <b>
              Das Telefonieren ohne Freisprechanlage sowie das Bedienen des
              Smartphones während der Fahrt ist eigentlich überall verboten!
            </b>
          </p>
          <v-card class="my-4">
            <v-card-title>
              Verkehrsregeln nach Land
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Nach Land suchen"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-select
              v-model="value"
              :items="shownValues"
              label="Kategorie auswählen"
              multiple
              return-object
              hint="Wähle die Kategorien die angezeigt werden"
              persistent-hint
              class="select ma-4"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 1" class=""
                  >({{ value.length }} ausgewählt)</span
                >
              </template>
            </v-select>

            <v-data-table
              class="mytable"
              :headers="selectedHeaders"
              :items="table"
              :search="search"
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.phone`]="{ item }">
                <v-icon v-if="item.phone == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.phone == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div v-if="item.phone !== 'nein' && item.phone !== 'ja'">
                  {{ item.phone }}
                </div>
              </template>
              <template v-slot:[`item.helmet`]="{ item }">
                <v-icon v-if="item.helmet == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.helmet == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div v-if="item.helmet !== 'nein' && item.helmet !== 'ja'">
                  {{ item.helmet }}
                </div>
              </template>
              <template v-slot:[`item.reflectors`]="{ item }">
                <v-icon v-if="item.reflectors == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.reflectors == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="item.reflectors !== 'nein' && item.reflectors !== 'ja'"
                >
                  {{ item.reflectors }}
                </div>
              </template>
              <template v-slot:[`item.side_by_side`]="{ item }">
                <v-icon v-if="item.side_by_side == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.side_by_side == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="
                    item.side_by_side !== 'nein' && item.side_by_side !== 'ja'
                  "
                >
                  {{ item.side_by_side }}
                </div>
              </template>
              <template v-slot:[`item.turn_right_red`]="{ item }">
                <v-icon
                  v-if="item.turn_right_red == 'ja'"
                  color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon
                  v-if="item.turn_right_red == 'nein'"
                  color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="
                    item.turn_right_red !== 'nein' &&
                    item.turn_right_red !== 'ja'
                  "
                >
                  {{ item.turn_right_red }}
                </div>
              </template>
              <template v-slot:[`item.bike_paths`]="{ item }">
                <v-icon v-if="item.bike_paths == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.bike_paths == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="item.bike_paths !== 'nein' && item.bike_paths !== 'ja'"
                >
                  {{ item.bike_paths }}
                </div>
              </template>
              <template v-slot:[`item.legal`]="{ item }">
                <v-icon v-if="item.legal == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.legal == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div v-if="item.legal !== 'nein' && item.legal !== 'ja'">
                  {{ item.legal }}
                </div>
              </template>
              <template v-slot:[`item.earphones`]="{ item }">
                <v-icon v-if="item.earphones == 'ja'" color="green darken-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="item.earphones == 'nein'" color="red darken-2"
                  >mdi-close</v-icon
                >
                <div
                  v-if="item.earphones !== 'nein' && item.earphones !== 'ja'"
                >
                  {{ item.earphones }}
                </div>
              </template>
            </v-data-table>
          </v-card>

          <p>
            Die angegebenen
            <b>Promille-Grenzen</b>
            gelten für volljährige Fahrer über 18 oder 21 Jahren (je nach Land)
            außerhalb der Probezeit.
          </p>
          <p>
            Einige Länder wie Frankreich, Italien oder Kroatien fordern
            außerdem, dass Radfahrer bei schlechter Sicht
            <b>Reflektoren am Menschen</b>
            wie beispielsweise eine Warnweste tragen. Das bezieht sich unter
            anderem auf das Fahren durch Tunnel, bei Nacht oder bei
            wetterbedingter schlechter Sicht.
          </p>
          <p>
            Beim
            <b>Nebeneinander fahren</b>
            gilt die Erlaubnis meist, wenn der sonstige Verkehr nicht am
            Überholen und der reguläre Straßenverkehr nicht am Fluss gehindert
            werden.
          </p>
          <p>
            Wichtig ist auch, dass in den Ländern, in denen eine
            <b>Pflicht besteht Fahrradwege zu nutzen</b>
            dies explizit auch dann gilt, wenn diese in schlechtem Zustand sind.
          </p>
          <p>
            Es gibt einige Länder, in denen beim Falle eines Unfalls mit einem
            PKW/ LKW der Radfahrer
            <b>nicht automatisch im Rech ist</b>
            . Dazu zählen in Europa Großbritannien, Irland, Malta, Rumänien und
            Zypern.
          </p>
          <div
            id="3"
            class="scroll-header text-h5 text-left blue-grey--text text--darken-1 ma-0 mt-4 mb-2"
          >
            3. Wissenswertes
          </div>
          <p>
            Außerdem gibt es mancherorts Verkehrsregeln für Radfahrer, die es
            nicht in unsere Tabelle geschafft haben. Dazu zählt beispielsweise,
            dass in Dänemark direktes Links abbiegen verboten ist. Möchten
            Radfahrer also an einer Kreuzung links abbiegen müssen sie die
            Kreuzung zunächst überqueren, dann wenden und somit rechts abbiegen.
            <br />
            In Großbritannien gibt es keine direkte Vorfahrtsregelung, die
            Vorfahrt ist an jeder Kreuzung ausgeschildert. Außerdem dürfen dort
            stehende Kolonnen explizit überholt werden. Gleiches gilt für die
            Schweiz, in Spanien wiederum ist das Vorbeifahren an stehenden
            Autokolonnen untersagt.
            <br />
            In Litauen sind als einziges europäisches Land auch Radfahrer von
            der Tagfahrlicht-Pflicht betroffen.
            <br />
            Polen schützt seine Radfahrer, indem dort ab einer zulässigen
            Geschwindigkeit von 50 Km/h auf dem Gehweg gefahren werden darf,
            sofern dieser mindestens 2 Meter breit ist. Ähnlich ist dies in
            Belgien. Dort dürfen Radfahrer außerhalb der Stadtgrenzen auch den
            Grünstreifen oder Gehweg benutzen, wenn kein Radweg vorhanden ist.
            In Norwegen dürfen Radfahrer den Gehweg nutzen, wenn sie in
            Schrittgeschwindigkeit fahren.
            <br />
            Das für uns skurrilste Verbot: in Tschechien darf während des
            Radfahrens nicht geraucht werden. Das wäre uns sowieso nicht in den
            Sinn gekommen ;)
          </p>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="0"
        md="3"
        class="d-flex flex-column align-end justify-start"
      >
        <v-card style="position: sticky; top: 60px" color="#495867" width="260">
          <v-card-title class="pa-2 white--text">Übersicht</v-card-title>
          <v-list class="py-1" dense two-line>
            <v-list-item @click="scrollTo(1)">
              <v-list-item-title
                >1. Verkehrsregeln für Radfahrer in Europa</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="scrollTo(2)">
              <v-list-item-title>2. Tabellarische Übersicht</v-list-item-title>
            </v-list-item>
            <v-list-item @click="scrollTo(3)">
              <v-list-item-title>3. Wissenswertes</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      value: [],
      shownValues: [],
      selectedHeaders: [],
      headers: [
        {
          text: "Country",
          align: "start",
          sortable: false,
          value: "country",
        },
        { text: "Fahrseite", value: "driving_site" },
        { text: "Telefonieren/ Handynutzung erlaubt", value: "phone" },
        { text: "Helmpflicht", value: "helmet" },
        {
          text: "Reflektoren am Menschen bei schlechter Sicht",
          value: "reflectors",
        },
        { text: "Nebeneinander Fahren", value: "side_by_side" },
        { text: "Rechtsabbiegen bei Rot", value: "turn_right_red" },
        { text: "Pflicht Fahrradwege zu Benutzen", value: "bike_paths" },
        { text: "Promille Grenze", value: "alcohol_limit" },
        {
          text: "Schuld bei Unfall mit Auto: Rad Automatisch im Recht?",
          value: "legal",
        },
        { text: "Kopfhörer erlaubt", value: "earphones" },
      ],
      table: [
        {
          country: "Albanien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.1",
          legal: "",
          earphones: "",
        },
        {
          country: "Andorra",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Belgien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "ja",
          turn_right_red: "ja",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Bosnien-Herzegowina",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.3",
          legal: "",
          earphones: "",
        },
        {
          country: "Bulgarien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Dänemark",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "nur wenn kein Verkehr",
          turn_right_red: "ja",
          alcohol_limit: "pers. Belastbarkeit",
          legal: "",
          earphones: "",
        },
        {
          country: "Deutschland",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "mit Beschilderung",
          bike_paths: "",
          alcohol_limit: "1.60",
          legal: "",
          earphones: "",
        },
        {
          country: "Estland",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.19",
          legal: "",
          earphones: "",
        },
        {
          country: "Finnland",
          driving_site: "R",
          helmet: "ja",
          reflectors: "",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "pers. Belastbarkeit",
          legal: "",
          earphones: "",
        },
        {
          country: "Frankreich",
          driving_site: "R",
          helmet: "",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "ja",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Georgien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.2",
          legal: "",
          earphones: "",
        },
        {
          country: "Griechenland",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "nein",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Großbritannien",
          driving_site: "L",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "ja",
          bike_paths: "nein",
          alcohol_limit: "pers. Belastbarkeit",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Irland",
          driving_site: "L",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "pers. Belastbarkeit",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Island",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.2",
          legal: "",
          earphones: "",
        },
        {
          country: "Italien",
          driving_site: "R",
          helmet: "",
          reflectors: "ja",
          phone: "",
          side_by_side: "nein",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Kosovo",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Kroatien",
          driving_site: "R",
          helmet: "U16",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Lettland",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Liechtenstein",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.8",
          legal: "",
          earphones: "",
        },
        {
          country: "Litauen",
          driving_site: "R",
          helmet: "U18",
          reflectors: "ja",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.4",
          legal: "",
          earphones: "",
        },
        {
          country: "Luxemburg",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Mazedonien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Malta",
          driving_site: "L",
          helmet: "ja",
          reflectors: "ja",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.8",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Moldovien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Monaco",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Montenegro",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Niederlande",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "ja",
          turn_right_red: "ja",
          bike_paths: "",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Norwegen",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "pers. Belastbarkeit",
          legal: "",
          earphones: "",
        },
        {
          country: "Österreich",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "nein",
          side_by_side: "nein",
          bike_paths: "ja",
          alcohol_limit: "0.8",
          legal: "",
          earphones: "",
        },
        {
          country: "Polen",
          driving_site: "R",
          helmet: "U12",
          reflectors: "",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "0.2",
          legal: "",
          earphones: "",
        },
        {
          country: "Portugal",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "nur auf Radwegen",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Rumänien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0",
          legal: "nein",
          earphones: "",
        },
        {
          country: "Russland",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0.356",
          legal: "",
          earphones: "",
        },
        {
          country: "San Marino",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Schweden",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "pers. Belastbarkeit",
          legal: "",
          earphones: "",
        },
        {
          country: "Schweiz",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "nein",
          side_by_side: "nein",
          turn_right_red: "mit Beschilderung",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Serbien",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          alcohol_limit: "0.3",
          legal: "",
          earphones: "",
        },
        {
          country: "Slowakei",
          driving_site: "R",
          helmet: "U18, Außerorts",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          alcohol_limit: "0.0",
          legal: "",
          earphones: "",
        },
        {
          country: "Slowenien",
          driving_site: "R",
          helmet: "U18",
          reflectors: "ja",
          turn_right_red: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "",
        },
        {
          country: "Spanien",
          driving_site: "R",
          helmet: "Außerorts",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "nein",
          turn_right_red: "",
          bike_paths: "ja",
          alcohol_limit: "0.5",
          legal: "",
          earphones: "nein",
        },
        {
          country: "Tschechische Republik",
          driving_site: "R",
          helmet: "U18",
          reflectors: "",
          bike_paths: "Ja",
          alcohol_limit: "0.0",
          legal: "",
          earphones: "",
        },
        {
          country: "Türkei",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Ukraine",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0",
          legal: "",
          earphones: "",
        },
        {
          country: "Ungarn",
          driving_site: "R",
          helmet: "",
          reflectors: "ja",
          phone: "nein",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "0",
          legal: "",
          earphones: "",
        },
        {
          country: "Vatikan",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Weißrussland",
          driving_site: "R",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "",
          earphones: "",
        },
        {
          country: "Zypern",
          driving_site: "L",
          helmet: "",
          reflectors: "",
          phone: "",
          side_by_side: "",
          turn_right_red: "",
          bike_paths: "",
          alcohol_limit: "",
          legal: "nein",
          earphones: "",
        },
      ],
    };
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  watch: {
    value(val) {
      let country = {
        text: "Country",
        align: "start",
        sortable: false,
        value: "country",
      };
      let arr = JSON.parse(JSON.stringify(val));
      arr.unshift(country);
      console.log(arr);
      this.selectedHeaders = arr;
    },
  },
  created() {
    this.selectedHeaders = this.headers;
    let arr = JSON.parse(JSON.stringify(this.headers));
    const indexOfObject = arr.findIndex((object) => {
      return object.value === "country";
    });

    arr.splice(indexOfObject, 1);
    this.shownValues = arr;
    this.value = arr;
  },
};
</script>

<style scoped>
.select {
  width: 200px;
}
.v-data-table-header {
  background-color: #eccbb6 !important;
}
</style>
